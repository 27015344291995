define('extensions/knockout/bindings/expand', [], function () {
    ko.bindingHandlers.expand = {
		init: function (element, valueAccessor) {
			var config = valueAccessor() || {},
				excludedNodes = config.exclude || [];

			var oShrinkBy = ko.observable(0);

			var update = _.throttle(function () {
				requestAnimationFrame(function () {
					var shrinkBy = _(excludedNodes).reduce(function (result, node) {
						return result + $(node).outerHeight();
					}, 0);

					oShrinkBy(Math.ceil(shrinkBy));
				});
			}, 100);

			var observer = new MutationObserver(update);

			observer.observe(document.body, {
				attributes: true,
				subtree: true,
				childList: true
			});

			var sShrinkBy = oShrinkBy.subscribe(function (value) {
				$(element).css({
					'min-height': 'calc(100vh - ' + value + 'px)'
				});
			});

			return {
				dispose: function () {
					observer.disconnect();
					sShrinkBy.dispose();
				}
			};
        }
    };
});