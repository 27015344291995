import ko from 'knockout';

ko.subscribable.fn.toPx = function (allowNull) {
    const observable = this;

    return ko.pureComputed(() => {
        const value = observable();

        if (_.isNumber(value)) {
            return `${value}px`;
        } else if (_.isString(value)){
            return value;
        } else {
            return allowNull ? '' : 'auto';
        }
    });
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * converts inner value into px-form value and returns as computed
         * example: ko.observable(23) => ko.pureComputed('23px')
         * @param allowNull if false null value will be replaced with 'auto'
         */
        toPx(allowNull?: boolean): Subscribable<string>
    }
}