import * as ko from 'knockout';

ko.subscribable.fn.invoke = function(...args: Array<any>) {
    var observable = this;

    if (_.isString(args[0])) {
        let field = args[0] as string,
            allArgs = args;

        let func = (value: any) => {
            var wrapper = _(value);

            if (wrapper.isArray()) {
                return wrapper.invoke.apply(wrapper, allArgs as any);
            } else if (_.isFunction(value[field])) {
                return value[field].apply(value, args.slice(1));
            } 

            return undefined;
        }

        return func(observable());
    } else if (_.isFunction(args[0])) {
        let func = args[0] as (value: any) => any,
            context = args[1] as any,
            ignoreDependencies = args[2] as boolean | undefined;

        return ignoreDependencies ?
            ko.ignoreDependencies(func, context, [observable()]) :
            func.call(context, observable());
    }

    return undefined;
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * invokes functions against inner value
         * @param func function to call
         * @param context function context to be executed in(this)
         * @param ignoreDependencies calls function inside ignoreDependencies wrapper if true
         */
        invoke<K>(func: (value: T) => K, context?: any, ignoreDependencies?: boolean): K

        /**
         * invokes inner object method with specified name
         * @param funcToCall function to call
         * @param args arguments to pass to function
         */
        invoke(funcToCall: string, args: any[]): any
    }    
}