import ko, { SimpleValue } from 'knockout';

ko.subscribable.fn.sortBy = function <T, TSort>(iterator?: _.ListIterator<SimpleValue<T>, TSort>) {
    const observable = this;

    return ko.pureComputed(() => _(observable()).sortBy(iterator));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * sorts inner array using iterator
         * @param iterator
         */
        sortBy<TSort>(iterator?: _.ListIterator<SimpleValue<T>, TSort>): Subscribable<Array<SimpleValue<T>>>
    }
}