import ko, { SubscribableOrNullableValue } from 'knockout';
import { withEffects } from 'mixins/withEffects';

export interface KOHTMLBindingData {
    value?: SubscribableOrNullableValue<string>
    data?: SubscribableOrNullableValue<any>
}

ko.virtualElements.allowedBindings.kohtml = true

ko.bindingHandlers.kohtml = {
    init: function (element, valueAccessor) {
        const data = valueAccessor();
        const effects = withEffects();

        let value, model;

        if (data != undefined && !_.isFunction(data) && data.value != undefined) {
            value = ko.flattenComputed(data.value, '');
            model = ko.flattenComputed(data.data, {});
        } else {
            value = ko.flattenComputed(data);
            model = ko.pureComputed(() => ({}));
        }

        effects.register((value, model) => {
            var nodes = ko.utils.parseHtmlFragment(value);

            ko.virtualElements.setDomNodeChildren(element, nodes);
            ko.applyBindingsToDescendants(model, element);
        }, [value, model]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());

        return { controlsDescendantBindings: true };
    }
}