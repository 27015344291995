import ko from 'knockout';

ko.subscribable.fn.unwrapPromise = function (defaultValue?: any) {
    const observable = this;    

    let result = ko.pureComputed(() => {
        const result = ko.observable<any>();
        const value = observable();

        if (value && value.then)
            (<PromiseLike<any>>value).then(val => result(val));
        else
            result(value);

        return result;
    }).unwrap();

    if (defaultValue != undefined)
        result = result.default(defaultValue);

    return result;
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * transforms Subscribable<Promise<R>> into Subscribable<R | undefined>
         * Subscribable will have underfined value until Promise is resolved
         */
        unwrapPromise(): Subscribable<T extends Promise<infer R> ? R | undefined : T>
        unwrapPromise(defaultValue: T extends Promise<infer R> ? NonNullable<R> : never): Subscribable<T extends Promise<infer R> ? NonNullable<R> : T>
    }
}