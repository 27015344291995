import ko from 'knockout';
import { withEffects } from 'mixins/withEffects';

ko.virtualElements.allowedBindings.safehtml = true;

ko.bindingHandlers.safehtml = {
    init: function (element, valueAccessor) {
        const html = valueAccessor();
        const effects = withEffects();

        effects.register(html => {
            ko.virtualElements.setDomNodeChildren(element, ko.utils.parseHtmlFragment(html));
        }, [html]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());

        return { controlsDescendantBindings: true };
    }
}