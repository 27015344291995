import AutoNumeric from 'autonumeric';
import ko from 'knockout';
import { withEffects } from 'mixins/withEffects';

export interface AutoNumericFormatOptions {
    langOptions?: AutoNumericLanguageOptions
    decimalPlaces?: number
    digits?: number
}

interface AutoNumericLanguageOptions {
    decimalCharacter?: string
}

ko.bindingHandlers.autoNumeric = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const effects = withEffects();
        const formatOptions = <AutoNumericFormatOptions>allBindingsAccessor().formatOptions;
        const $element = $(element);

        const nOptions = <AutoNumeric.Options>{
            decimalCharacter: '.',
            digitGroupSeparator: '',
            minimumValue: '0'            
        }

        if (formatOptions != undefined) {
            if (formatOptions.langOptions?.decimalCharacter != undefined) nOptions.decimalCharacter = formatOptions.langOptions.decimalCharacter;
            if (formatOptions.decimalPlaces != undefined) nOptions.decimalPlaces = formatOptions.decimalPlaces;
            if (formatOptions.digits != undefined) nOptions.maximumValue = ((Math.pow(10, formatOptions.digits) - 1) / Math.pow(10, formatOptions.decimalPlaces ?? 0)).toString();
        }

        const value = ko.flattenComputed(valueAccessor());
        var autonumericObj = new AutoNumeric(element, undefined, nOptions);

        $element.data('autonumericobj', autonumericObj);

        effects.register(value => {
            if (_.isString(value) && value.length > 0)
                autonumericObj.setUnformatted(<any>value);
        }, [value]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            const autonumeric = $element.data('autonumericobj');

            if (autonumeric)
                autonumeric.remove();

            effects.dispose();
        });
    }
};