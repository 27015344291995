import ko, { SubscribableOrNullableValue } from 'knockout';

ko.multistreamComputed = function <T>(...streams: SubscribableOrNullableValue<T>[]) {
    const value = ko.observable<T>();

    const result = ko.pureComputed(() => {
        const newValue = streams.reduce((result, stream) => {
            if (result == undefined)
                return ko.unwrap(stream);

            return result;
        }, <T | undefined>undefined);

        if (newValue != undefined)
            value(newValue);

        return value();
    });

    return result;
}

declare module 'knockout' {
    /**
     * Creates computed which returns first non-null value from all streams or it's last value
     * in other words every stream can write it's value into inner cache only when it's not null and all streams before it are null
     * @param streams input streams of values
    */
    export function multistreamComputed<T>(...streams: SubscribableOrNullableValue<T>[]): Subscribable<T | undefined>
}