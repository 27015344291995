import ko, { Subscribable, SubscribableOrNullableValue } from 'knockout';
import { withEffect } from 'mixins/withEffect';
import { withEffects } from 'mixins/withEffects';

ko.virtualElements.allowedBindings.customElement = true;

function buildParams(params?: StringMap<any>) {
    if (params == undefined)
        params = {}

    if (!('model' in params))
        params = { model: params };

    if (!('ref' in params))
        params = { ...params, ref: undefined }

    return params;
}

export interface CustomElementConfig<T extends StringMap<any> = StringMap<any>, R = any> {
    name: SubscribableOrNullableValue<string>
    params?: T | CustomElementExtendedParams<T, R>
}

export interface CustomElementExtendedParams<T extends StringMap<any> = StringMap<any>, R = any> {
    model: T,
    ref?: Subscribable<R>
}

export const getCustomElement = <T, V = any>(name: string, model: T, ref?: Subscribable<V | undefined> | Subscribable<V[]>): CustomElementConfig<T> => ({ name, params: { model, ref } });

ko.bindingHandlers.customElement = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const effects = withEffects();
        const value = ko.flattenComputed(<SubscribableOrNullableValue<CustomElementConfig>>valueAccessor());

        const children = ko.virtualElements.childNodes(element);

        effects.register(value => {
            if (value != undefined) {
                const name = ko.flattenComputed<string | undefined>(value.name);
                const params = buildParams(value.params);

                return withEffect(name => {
                    if (name != undefined && name.length > 0) {
                        const root = $('<' + name + ' params="model: model, ref: ref"><' + name + '/>')[0];                        

                        ko.virtualElements.setDomNodeChildren(root, children.map(node => node.cloneNode(true)));
                        ko.virtualElements.setDomNodeChildren(element, [root]);

                        ko.applyBindings(bindingContext.createChildContext(params), root);
                    } else {
                        ko.virtualElements.setDomNodeChildren(element, []);
                    }
                }, [name]);
            } else {
                ko.virtualElements.setDomNodeChildren(element, []);
            }
        }, [value]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());

        return { 'controlsDescendantBindings': true };
    }
}