import ko, { Observable } from 'knockout';

ko.subscribable.fn.toEntity = function <T, K>(factory: (data: NonNullable<T>) => K, update?: (entity: NonNullable<K>, data: NonNullable<T>) => void) {
    const observable = this;
    const result: Observable<NonNullable<K> | undefined> = ko.observable();

    return ko.pureComputed({
        read: () => {
            var value = observable();

            return ko.ignoreDependencies(() => {
                if (value != undefined) {
                    const entity = result();

                    if (entity == undefined || update == undefined) {
                        const newEntity = factory(value);
                        result(newEntity != undefined ? newEntity as NonNullable<K> : undefined);
                    } else {
                        update(entity as NonNullable<K>, value);
                    }
                } else {
                    result(undefined);
                }

                return result();
            });
        },

        write: value => observable(value)
    }).extend({ notifyIfChanged: true });
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Creates entity and update it on observable change
         * @param {any} factory factory method
         * @param {any} update update func
         * @returns {computed} new computed with entity inside
         */
        toEntity<K>(factory: (data: NonNullable<T>) => K, update?: (entity: NonNullable<K>, data: NonNullable<T>) => void): PureComputed<NonNullable<K> | undefined>
    }
}