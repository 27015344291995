define('extensions/knockout/functions/trackChanges', [
    'services/knockout',
    'knockout'
], function (koServices, ko) {
    ko.subscribable.fn.trackChanges = function (opts) {
        let array = ko.observableArray();

        let s1 = array.attachTo(this),
            s2 = array.subscribe(koServices.onArrayChanged(opts), null, 'arrayChange');

        return { dispose: () => [s1, s2].forEach(s => s.dispose()) };
    };
});