import * as ko from 'knockout';

ko.subscribable.fn.whenChanged = function <T>(this: ko.Subscribable<T>, disposalPromise?: Promise<boolean>) {
    const initial = this();
    return this.when(value => value !== initial, disposalPromise);
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * creates promise which is resolve when inner value is not equal to current one
         * @param disposalPromise if that promise is resolved then we stop tracking subscribable value
         */
        whenChanged(disposalPromise?: Promise<boolean>): Promise<T>
    }
}