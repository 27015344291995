import ko, { SubscribableOrNullableValue } from 'knockout';

const { now } = settings;

interface TimeConditionOptions {
    after?: SubscribableOrNullableValue<Date>
    before?: SubscribableOrNullableValue<Date>    
}

ko.timeCondition = function <T, K>(options: TimeConditionOptions) {
    const computedAfter = ko.flattenComputed(options.after);
    const computedBefore = ko.flattenComputed(options.before);    

    let lastBefore: Date | undefined
    let lastAfter: Date | undefined

    let isAlwaysFalse = false;
    let isAlwaysTrue = false;

    return ko.pureComputed(() => {
        const after = computedAfter();
        const before = computedBefore();

        if (before != undefined) {
            //before condition is not changed and we gone outside of allowed boundary
            if (before === lastBefore && isAlwaysFalse)
                return false;

            lastBefore = before;
            isAlwaysFalse = now() > before;

            if (isAlwaysFalse)
                return false;                
        }

        if (after != undefined) {
            if (after === lastAfter && isAlwaysTrue)
                return true;

            lastAfter = after;
            isAlwaysTrue = now() > after;

            if (isAlwaysTrue)
                return true;
        }

        //run regular checks
        if (after != undefined && before != undefined)
            return now() >= after && now() <= before;

        if (after != undefined)
            return now() >= after;

        if (before != undefined)
            return now() <= before;

        //invalid boundaries so return false
        return false;
    });
}

declare module 'knockout' {
    /**
     * creates special computed which returns true if current time is inside specified boundaries
     * it stops tracking current time once it goes outside of specified boundaries removing unnecessary evaluations
     * @param options
     */
    export function timeCondition(options: TimeConditionOptions): PureComputed<boolean>
}