import ko, { Subscribable } from 'knockout';

ko.subscribable.fn.isEmpty = function <T>(this: Subscribable<T>) {
    return ko.pureComputed(() => _.isEmpty(this()));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /** returns computed which indecates whatever inner collection is empty underscore isEmpty func to inner value and returns as computed */
        isEmpty(): Subscribable<boolean>
    }
}