import * as ko from 'knockout';

ko.subscribable.fn.when = function <T>(this: ko.Subscribable<T>, predicate?: (item: T) => boolean, disposalPromise?: Promise<boolean>) {
    const finalPredicate = predicate ?? (item => !!item);

    const observable = this;

    return new Promise(resolve => {
        const result = ko.pureComputed(() => finalPredicate(observable()) === true);

        if (result() === true) {
            resolve(observable());
        } else {
            const sub = result.subscribe(result => {
                if (result === true) {
                    resolve(observable());
                    sub.dispose();
                }
            });

            if (disposalPromise)
                disposalPromise.then(() => sub.dispose());
        }
    });
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * creates promise which is resolve when predicate is resolved to true
         * @param predicate Predicate we call agains subscribable value
         * @param disposalPromise if that promise is resolved then we stop tracking subscribable value
         */
        when(predicate?: (item: T) => boolean, disposalPromise?: Promise<boolean>): Promise<T>
    }
}