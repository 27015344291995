import { getObservableAccesser } from 'helpers/knockout';
import { Func } from 'interfaces/func';
import ko, { Observable } from 'knockout';
import { ObservableMapping } from 'models/observableMapping';

ko.subscribable.fn.mapNotNull = function<T, K>(this: Observable<T>, predicateOrConfig: Func<K, [NonNullable<T>]> | ObservableMapping<NonNullable<T>, K>, context?: any, ignoreDependencies?: boolean) {
    const observable = this;
    const acceser = getObservableAccesser(this, predicateOrConfig);

    return ko.pureComputed({
        read: () => {
            const val = observable();

            if (val == undefined) {
                return undefined;
            } else {
                return ignoreDependencies ?
                    ko.ignoreDependencies(acceser.read, context, [val]) :
                    acceser.read.call(context, val);
            }
        },

        write: (value) => ko.ignoreDependencies(() => acceser.write.call(context, observable(), value))
    });
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
        * returns computed which maps current value into another value using predicate if current value is not null otherwise returns null
        * @param predicate
        * @param context
        * @param ignoreDependencies
        */
        mapNotNull<K>(predicate: Func<K, [NonNullable<T>]>, context?: any, ignoreDependencies?: boolean): PureComputed<K | undefined>

        /**
         * returns computed which maps current value into another value using config if current value is not null otherwise returns null
         * supports write operation if write func is provided inside config
         * @param config
         * @param context
         * @param ignoreDependencies
         */
        mapNotNull<K>(config: ObservableMapping<NonNullable<T>, K>, context?: any, ignoreDependencies?: boolean): PureComputed<K | undefined>
    }
}