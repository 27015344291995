define('extensions/knockout/bindings/centerNode', [
    'managers/disposables'
], function (Disposables) {
    ko.bindingHandlers.centerNode = {
        init: function (element, valueAccessor) {
            let disposables = new Disposables(),
                value = valueAccessor();

            var processor = ko.computed(() => {
                disposables.dispose();
                disposables = new Disposables();

                if (ko.unwrap(value))
                    ko.ignoreDependencies(() => {
                        let $element = $(element),
                            $window = $(window);

                        let offset = ko.observable(0);

                        let update = _.debounce(() => {
                            let { top, height } = element.getBoundingClientRect();

                            let newOffset = window.innerHeight / 2 - (top + $window.scrollTop() + height / 2) + offset();

                            offset(newOffset > 0 ? Math.ceil(newOffset) : 0);
                        }, 0);

                        disposables.register(new MutationObserver(update),
                            observer => observer.observe(element, { attributes: true, childList: true, subtree: true }),
                            observer => observer.disconnect());

                        disposables.register(
                            () => $window.on('resize', update),
                            () => $window.off('resize', update));

                        disposables.register(
                            _.noop,
                            () => $element.css('margin-top', ''));

                        [
                            offset.subscribe(value => $element.css('margin-top', value))
                        ].forEach(s => disposables.register(s));

                        update();
                    });
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                processor.dispose();
                disposables.dispose();
            });
        }
    };
});