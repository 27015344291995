import ko, { SubscribableOrNullableValue } from 'knockout';
import { withEffects } from 'mixins/withEffects';

ko.bindingHandlers.slideToggle = {
    init: (element, valueAccessor, allBindings) => {
        const effects = withEffects();

        const $element = $(element);        
        const isExpanded = ko.flattenComputed(<SubscribableOrNullableValue<boolean>>valueAccessor(), false);
        const doneCallback = allBindings.get('slideToggle-done');

        let isInitial = true;

        effects.register(isExpanded => {
            if (isInitial) {
                if (isExpanded) {
                    $element.show();
                } else {
                    $element.hide();
                }

                isInitial = false;
            } else {
                if (isExpanded) {
                    $element.stop(true, true).slideDown({
                        duration: allBindings().slideDuration,

                        start: () => {
                            if (allBindings().showOverflow)
                                $element.css('overflow', 'visible');
                        },

                        complete: doneCallback
                    });
                } else {
                    $element.stop(true, true).slideUp({
                        duration: allBindings().slideDuration,
                        complete: doneCallback
                    });
                }
            }
        }, [isExpanded], false);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());
    }
}