import ko from 'knockout';

ko.subscribable.fn.readOnly = function () {
    const observable = this;

    return ko.pureComputed(() => observable());
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /** converts subscribable into readonly computed */
        readOnly(): Subscribable<T>
    }
}