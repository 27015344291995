import ko from 'knockout';

ko.subscribable.fn.unwrap = function (selfWriteOnly = true) {
    const observable = this;

    return observable.mapSingle({
        read: item => ko.unwrap(item),
        write: (unwrapped, value) => {
            if (ko.isWritableObservable(unwrapped) && !selfWriteOnly)
                unwrapped(value);
            else
                observable(value);
        }
    });
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * converts observable<observable<T>> into observable<T>
         * with that func we can create fields which accepts raw values and observables at the same time
         * @param selfWriteOnly if false will try to write value changes into inner observable which allows to create proxy for another observable
         */
        unwrap(selfWriteOnly?: boolean): PureComputed<WCCUnwrapped<T>>
    }
}