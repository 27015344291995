define('extensions/knockout/functions/history', [
    'knockout'
], (ko) => {
    let pushToQueue = (queue, length) => (item) => {
        queue.unshift(item);

        if (queue().length > length)
            queue.pop();
    };

    /**
     * creates computed which returns previous subscribable value
     */
    ko.subscribable.fn.history = function (length) {
        length = length || 1;

        let value = ko.observableArray([]),
            result = value.readOnly();

        let push = pushToQueue(value, length);

        var sub = this.subscribe(v => push({ value: v, timestamp: Date.now() }));

        var _dispose = result.dispose.bind(result);

        result.dispose = () => {
            _dispose();
            sub.dispose();
        };

        return result;
    };
});