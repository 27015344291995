import ko, { Observable, SimpleValue } from 'knockout';
import { Func } from '../../../interfaces/func';

const defaultMapper = () => true;

ko.subscribable.fn.count = function <T>(this: Observable<SimpleValue<T>[]>, mapper?: Func<number | boolean, [value: SimpleValue<T>]>) {
    const mapperOrDefault = mapper ?? defaultMapper;

    return this.reduce((result, item) => {
        const value = mapperOrDefault(item);

        if (_.isNumber(value))
            return result + value;
        else
            return value ? result + 1 : result;
    }, () => 0);
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * reduces array into single number using mapper to get each item value
         */
        count(mapper?: Func<number, [value: SimpleValue<T>]>): Subscribable<number>
    }
}