import * as ko from 'knockout';

ko.subscribable.fn.toPrts = function (allowNulls = false) {
    const observable = this;

    return ko.pureComputed(() => system.toPercents(observable(), allowNulls));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * converts values into % form and returns as computed
         * ko.observable(1) => ko.observable(1%)
         * */
        toPrts(allowNulls?: boolean): Subscribable<string | undefined>
    }
}