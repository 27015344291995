import ko, { Subscribable, SubscribableOrNullableValue, SubscribableOrValue, WCCUnwrapped } from 'knockout';

type FieldMapper<T, K> = (value: Field<T>) => SubscribableOrValue<K>
type NullableFieldMapper<T, K> = (value: Field<T>) => SubscribableOrValue<K> | undefined
type Field<T> = T extends Array<infer R> ? R : NonNullable<T>

type ElementOrArrayElementField<T, K> = T extends Array<infer R> ? ElementField<R, K> : ElementField<T, K>
type ElementField<T, K> = K extends keyof T ? WCCUnwrapped<T extends undefined | null ? undefined : T[K]> : never
type Mapped<T, V> = T extends Array<any> ? Array<V> : T extends null | undefined ? undefined : V
type Defaulted<T, D> = T extends null | undefined ? D : T

ko.subscribable.fn.pluck = function <T, K>(this: Subscribable<T>, nameOrMapper: string | FieldMapper<T, K>, defaultValue?: SubscribableOrNullableValue<K>) {
    const observable = this;

    var mapper: (item: any) => any;

    if (_.isString(nameOrMapper))
        mapper = item => item[nameOrMapper];
    else
        mapper = item => nameOrMapper(item);

    const mappedObservable = ko.pureComputed(() => {
        const value = observable();

        if (_.isArray(value))
            return value.map(mapper);
        else if (value != undefined)
            return mapper(value);
    });

    return ko.pureComputed(() => {
        const value = mappedObservable();

        if (_.isArray(value))
            return value.map(item => ko.unwrap(item));
        else
            return ko.unwrap(value) ?? ko.unwrap(defaultValue);
    });
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * returns inner object field as computed
         * example: observable({ a:1, b:2 }).pluck('a') => Computed(1);
         * @param name
         * @param defaultValue
         */
        pluck<K extends string>(name: K): Subscribable<Mapped<T, ElementOrArrayElementField<T, K>>>

        /**
         * returns inner object field as computed or default value if field is null or underfined
         * example: observable({ a:1, b:2 }).pluck('a') => Computed(1);
         * @param name
         * @param defaultValue
         */
        pluck<K extends string>(name: K, defaultValue: SubscribableOrValue<NonNullable<ElementOrArrayElementField<T, K>>>): Subscribable<Defaulted<Mapped<T, ElementOrArrayElementField<T, K>>, NonNullable<ElementOrArrayElementField<T, K>>>>

        /**
         * returns inner object field as computed or default value if field is null or underfined
         * example: observable({ a:1, b:2 }).pluck('a') => Computed(1);
         * @param name
         * @param defaultValue
         */
        pluck<K extends string>(name: K, defaultValue: SubscribableOrNullableValue<ElementOrArrayElementField<T, K>>): Subscribable<Defaulted<Mapped<T, ElementOrArrayElementField<T, K>>, ElementOrArrayElementField<T, K> | undefined>>

        /**
         * returns object field as computed
         * example: observable({ a:1, b:2 }).pluck(item => item.a) => Computed(1);
         * @param mapper
         * @param defaultValue
         */
        pluck<K>(mapper: FieldMapper<T, K>): Subscribable<Mapped<T, K>>
        pluck<K>(mapper: NullableFieldMapper<T, K>): Subscribable<Mapped<T, K | undefined>>

        /**
         * returns object field as computed or default value if field is null or underfined
         * example: observable({ a:1, b:2 }).pluck(item => item.a) => Computed(1);
         * @param mapper
         * @param defaultValue
         */
        pluck<K>(mapper: FieldMapper<T, K>, defaultValue: SubscribableOrValue<NonNullable<K>>): Subscribable<Defaulted<Mapped<T, K>, NonNullable<K>>>
        pluck<K>(mapper: NullableFieldMapper<T, K>, defaultValue: SubscribableOrValue<NonNullable<K>>): Subscribable<Defaulted<Mapped<T, K>, NonNullable<K>>>

        /**
         * returns object field as computed or default value if field is null or underfined
         * example: observable({ a:1, b:2 }).pluck(item => item.a) => Computed(1);
         * @param mapper
         * @param defaultValue
         */
        pluck<K>(mapper: FieldMapper<T, K>, defaultValue: SubscribableOrNullableValue<K>): Subscribable<Defaulted<Mapped<T, K>, K | undefined>>
        pluck<K>(mapper: NullableFieldMapper<T, K>, defaultValue: SubscribableOrNullableValue<K>): Subscribable<Defaulted<Mapped<T, K>, K | undefined>>
    }
}