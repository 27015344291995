import ko, { Subscribable } from 'knockout';
import { Func } from '../../../interfaces/func';
import { withEffects } from '../../../mixins/withEffects';

export interface DisposablesCollection<T, K> {
    values: Subscribable<K[]>
    dispose: Action
}

ko.disposablesCollection = function <T, K>(source: Subscribable<T[]>, factory: Func<K | undefined, [value: T]>, dispose: Action<[item: K]>) {
    const effects = withEffects();

    const map = new Map<T, K>();
    const collection = ko.observableArray<K>();

    effects.register(values => {
        values.forEach(value => {
            if (!map.has(value)) {
                const item = factory(value);

                if (item != undefined)
                    map.set(value, item);
            }
        });

        [...map.keys()].forEach(key => {
            if (!values.includes(key)) {
                const item = map.get(key);

                if (item != undefined)
                    dispose(item);

                map.delete(key);
            }
        });

        collection([...map.values()]);
    }, [source]);

    effects.register([
        () => {
            [...map.keys()].forEach(key => {
                const item = map.get(key);

                if (item != undefined)
                    dispose(item);

                map.delete(key);
            });

            collection([]);
        }
    ]);

    return <DisposablesCollection<T, K>>{
        values: collection.readOnly(),
        dispose: () => effects.dispose()
    }
}

declare module 'knockout' {
    /**
     * Creates computed from subscribable or raw value.
     * @param value
    */
    export function disposablesCollection<T, K>(source: Subscribable<T[]>, factory: Func<K | undefined, [value: T]>, dispose: Action<[item: K]>): DisposablesCollection<T, K>
}