import ko, { Subscribable, SubscribableOrNullableValue } from 'knockout';
import { withEffect } from 'mixins/withEffect';
import { withEffects } from 'mixins/withEffects';

export interface PositionBindingOptions {
    top?: SubscribableOrNullableValue<number | string>
    right?: SubscribableOrNullableValue<number | string>
    bottom?: SubscribableOrNullableValue<number | string>
    left?: SubscribableOrNullableValue<number | string>
}

function getCSSBinding($element: JQuery, property: string, value: Subscribable<string>) {
    return withEffect(value => {
        $element.css(property, value);

        return () => $element.css(property, '');
    }, [value]);   
}

/**
* Sets position of element
* if {value} is number then it will be converted to px
*/
ko.bindingHandlers.position = {
    init: (element, valueAccessor) => {
        const $element = $(element);
        const effects = withEffects();

        const value = ko.flattenComputed<PositionBindingOptions | undefined>(valueAccessor());
        const top = value.pluck(value => ko.unwrap(value.top)).toPx(true);
        const right = value.pluck(value => ko.unwrap(value.right)).toPx(true);
        const bottom = value.pluck(value => ko.unwrap(value.bottom)).toPx(true);
        const left = value.pluck(value => ko.unwrap(value.left)).toPx(true);

        effects.register([
            getCSSBinding($element, 'top', top),
            getCSSBinding($element, 'right', right),
            getCSSBinding($element, 'bottom', bottom),
            getCSSBinding($element, 'left', left)
        ]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());
    }
}