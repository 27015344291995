define('extensions/knockout/bindings/hideContext', [], () => {
    ko.virtualElements.allowedBindings.hideContext = true;

    /**
    * hides current context by setting current context to the specified one
    * puts current context into speficied valiable('$hidden' is default)
    * {value} should be object { toContext: {KO Context}, into: {variable name: '$hidden'} }.
    */
    ko.bindingHandlers.hideContext = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            let value = valueAccessor();

            let { toContext: context, store: toStore = { '$hidden': bindingContext } } = value.toContext ? value : { toContext: value };
            let newContext = { __proto__: context };

            _(context)
                .chain()
                .map((value, key) => ({ key: key, value: value }))
                .filter(item => item.key[0] == '$')
                .each(item => newContext[item.key] = item.value);

            _(toStore).each((context, name) => newContext[name] = context);

            ko.applyBindingsToDescendants(newContext, element);

            return { controlsDescendantBindings: true }
        }
    };
});