import { ImageHelpers } from 'helpers/images';
import ko, { Observable } from 'knockout';
import { withEffects } from 'mixins/withEffects';

ko.bindingHandlers.imageLoaded = {
    init: function (element: HTMLElement, valueAccessor) {
        const config = valueAccessor();

        let oLoaded: Observable<boolean> = ko.observable(false as boolean),
            oFailed: Observable<boolean> = ko.observable(false as boolean);

        if (ko.isWritableObservable(config)) {
            oLoaded = config;
        } else if (_.isObject(config)) {
            if (ko.isWritableObservable(config.value))
                oLoaded = config.value;
            else if (ko.isWritableObservable(config.loaded))
                oLoaded = config.loaded;

            if (ko.isWritableObservable(config.failed))
                oFailed = config.failed;
        }

        if (element instanceof HTMLImageElement) {
            const image = element as HTMLImageElement;
            const effects = withEffects();

            const awaiter: Observable<Observable<boolean | undefined> | undefined> = ko.observable();
            const result = ko.pureComputed(() => ko.unwrap(awaiter()));

            async function update() {
                awaiter(system.getPromiseAwaiter(async () => {
                    try {
                        await ImageHelpers.waitImage(image);
                        return true;
                    } catch (ex) {
                        return false;
                    }
                }));
            }

            effects.register(result => {
                if (result != undefined) {
                    oLoaded(result);
                    oFailed(!result);
                } else {
                    oLoaded(false);
                    oFailed(false);
                }
            }, [result]);

            effects.register(() => {
                const observer = new MutationObserver(update);
                observer.observe(image, { attributes: true, attributeFilter: ['src'] });

                update();

                return () => observer.disconnect();
            });

            ko.utils.domNodeDisposal.addDisposeCallback(image, () => effects.dispose());
        }        
    }
};