import ko from 'knockout';
import { DeepestListItemOrSelf, List, ListItemOrSelf, TypeOfList } from 'underscore';

ko.subscribable.fn.flatten = function (...args: any[]) {
    return ko.pureComputed(() => _(this()).flatten(...args));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        flatten(shallow?: false): T extends List<any> ? Subscribable<DeepestListItemOrSelf<TypeOfList<T>>[]> : never;
        flatten(shallow: true): T extends List<any> ? Subscribable<ListItemOrSelf<TypeOfList<T>>[]> : never;
    }
}