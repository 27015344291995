import ko from 'knockout';

interface LockedItem {
    target: Node
    priority: number
    func: Action
}

let stack: Array<LockedItem> = [];
let timeout: number | undefined;

function process () {
    if (stack.length > 0) {
        stack = _(stack).sortBy(item => item.priority);

        const item = <LockedItem>stack.shift();
        item.func();

        timeout = requestAnimationFrame(process);
    } else {
        timeout = undefined;
    }
}

function start() {
    timeout = requestAnimationFrame(process);
}

function add(item: LockedItem) {
    stack.push(item);

    if (timeout == undefined)
        start();
}

ko.virtualElements.allowedBindings.lock = true;

ko.bindingHandlers.lock = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        let executed = false;

        const priority = ko.unwrap(valueAccessor()) ?? 10000;
        const children = _.clone(ko.virtualElements.childNodes(element));        

        ko.virtualElements.emptyNode(element);

        var item = <LockedItem>{
            target: element,
            priority: priority,

            func: () => {
                ko.virtualElements.setDomNodeChildren(element, children);
                ko.applyBindingsToDescendants(bindingContext, element);

                executed = true;
                $(element).closest('div').trigger('ko-lock-released');
            }
        }

        $(element).closest('div').trigger('ko-lock-init');

        add(item);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            if (!executed)
                ko.utils.arrayRemoveItem(stack, item);
        });

        return { controlsDescendantBindings: true }
    }
}