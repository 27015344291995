import ko, { Subscribable } from 'knockout';

ko.subscribable.fn.isNotNull = function <T>(this: Subscribable<T>) {
    return this.is(value => value != undefined);
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns new computed which indicates whatever inner value is not null
         * @param predicate
         */
        isNotNull(): Subscribable<boolean>
    }
}