import { createExtendedContainer } from 'configuration/inversify/container/extended';
import { wccModules } from 'enums/wccModules';
import ko, { SubscribableOrNullableValue } from 'knockout';
import { IAutoCompleteManager, IAutoCompleteManagerBinding } from 'managers/autocomplete/autocomplete.interfaces';

export interface AutocompleteBindingOptions {
    target?: SubscribableOrNullableValue<string>
    bindings?: SubscribableOrNullableValue<IAutoCompleteManagerBinding[]>
}

ko.bindingHandlers.autocomplete = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const config: AutocompleteBindingOptions = valueAccessor();
        const [container, disposeContainer] = createExtendedContainer();
        const autocompleteManager = <IAutoCompleteManager>container.get(wccModules.autocompleteManager);

        autocompleteManager.start({
            container: element,
            target: config.target,
            bindings: config.bindings
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => disposeContainer());
    }
}