import ko, { SimpleValue } from 'knockout';
import { Func } from '../../../interfaces/func';

ko.subscribable.fn.uniq = function <T, V>(isSortedOrIteratee?: boolean | Func<V, [SimpleValue<T>]>, iteratee?: Func<V, [SimpleValue<T>]>) {
    const observable = this;

    if (arguments.length == 0) {
        return ko.pureComputed(() => _.uniq(observable()));
    } else if (arguments.length == 1) {
        iteratee = <Func<V, [SimpleValue<T>]> | undefined>isSortedOrIteratee;

        return ko.pureComputed(() => _.uniq(observable(), iteratee));
    } else {
        const isSorted = <boolean | undefined>isSortedOrIteratee;

        return ko.pureComputed(() => _.uniq(observable(), isSorted, iteratee));
    }
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        uniq(): Subscribable<SimpleValue<T>[]>;
        uniq(iteratee?: Func<any, [SimpleValue<T>]>): Subscribable<SimpleValue<T>[]>;
        uniq(isSorted?: boolean, iteratee?: Func<any, [SimpleValue<T>]>): Subscribable<SimpleValue<T>[]>;
        
    }
}