define('extensions/knockout/functions/selectNotNull', [
    'knockout'
], function (ko) {
    /**
     * projects observable value using func
     */
    ko.subscribable.fn.selectNotNull = function (func) {
        var observable = this,
            value = observable();

        func = func || _.identity;

        if (value != null) {
            return func(value);
        }

        return null;
    };
});