import ko from 'knockout';

ko.subscribable.fn.any = function(...args: any[]) {
    return ko.pureComputed(() => _(this()).any(...args));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed which indicates if inner array contains item which matches provided predicate
         * @param predicate
         */
        any(predicate?: (item: SimpleValue<T>) => boolean): Subscribable<boolean>
    }
}