import { Func } from 'interfaces/func';
import ko, { SimpleValue } from 'knockout';

ko.subscribable.fn.filter = function <T>(predicate?: Func<boolean, [SimpleValue<T>, number]>, ...args: Array<any>) {
    const observable = this;

    return ko.pureComputed(() => {
        const value = observable();

        if (_.isArray(value))
            return _(value).filter(predicate, ...args)
    });
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed with filtered inner array based on provided predicate
         * @param predicate
         */
        filter<K extends SimpleValue<NonNullable<T>> = SimpleValue<NonNullable<T>>>(predicate?: Func<boolean, [SimpleValue<NonNullable<T>>, number]>): Subscribable<T extends Array<any> ? Array<K> : T>
    }
}