import ko from 'knockout';

ko.subscribable.fn.first = function (n?: number): any {
    const observable = this;

    return n == undefined ?
        ko.pureComputed(() => _(observable()).first()) :
        ko.pureComputed(() => _(observable()).first(n));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed with first element in collection
         */
        first(): T extends Array<infer I> ? Subscribable<I | undefined> : never

        /**
         * Returns computed with first n elements in collection
         */
        first(n: number): T extends Array<infer I> ? Subscribable<Array<I>> : never
    }
}