import { getObservableAccesser } from 'helpers/knockout';
import { TextHelpers } from 'helpers/text';
import ko from 'knockout';
import 'libraries/intlTelInput';

ko.bindingHandlers.iCheck = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var $element = $(element);

        $element.iCheck({
            checkboxClass: 'icheckbox_' + (allBindings().iClass || 'flat-blue'),
            radioClass: 'iradio_' + (allBindings().iClass || 'flat-blue')
        }).on('ifChecked', () => {
            let value = valueAccessor();

            if (allBindings().useValue) {
                if (allBindings().isMultiple) {
                    if (value.indexOf($element.val()) == -1) {
                        value.push($element.val());
                    }
                } else {
                    value($element.val());
                }
            }
            else {
                value(true);

                _.defer(() => {
                    if (!value())
                        ko.bindingHandlers.iCheck.update(element, valueAccessor, allBindings, viewModel, bindingContext);
                });
            }
        }).on('ifUnchecked', () => {
            let value = valueAccessor();

            if (allBindings().useValue) {
                if (allBindings().isMultiple) {
                    value.remove($element.val());
                } else {
                    value(null);
                }
            }
            else {
                value(false);

                _.defer(() => {
                    if(value())
                        ko.bindingHandlers.iCheck.update(element, valueAccessor, allBindings, viewModel, bindingContext)
                });
            }
        });
    },

    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        let $element = $(element);
        let value = valueAccessor();

        if (allBindings().useValue) {
            if (allBindings().isMultiple && value.indexOf($element.val()) > -1 || !allBindings().isMultiple && value() == $element.val()) {
                $element.iCheck('check');
            } else {
                $element.iCheck('uncheck');
            }
        } else {
            if (value())
                $element.iCheck('check');
            else
                $element.iCheck('uncheck');
        }
    }
}

ko.bindingHandlers.iRadio = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element);
        eObj.iCheck({
            checkboxClass: 'icheckbox_' + (allBindings().iClass || 'flat-blue'),
            radioClass: 'iradio_' + (allBindings().iClass || 'flat-blue')
        }).on('ifChecked', function (event) {
            var value = valueAccessor(),
                objVal = eObj.val();
            if (allBindings().isNumber) {
                objVal = parseInt(objVal);
            }
            var oldVal = value();

            if (allBindings().isMultiple) {
                value([objVal]);
            }
            else {
                value(objVal);
            }

            var newVal = value();

            if (allBindings().valueChanged && oldVal != newVal) {
                allBindings().valueChanged(oldVal, newVal);
            }
        }).on('ifUnchecked', function (event) {
            var value = valueAccessor();
            var oldVal = value();

            if (allBindings().isMultiple) {
                value([]);
            }
            else {
                value(null);
            }

            var newVal = value();
            if (allBindings().valueChanged && oldVal != newVal) {
                allBindings().valueChanged(oldVal, newVal);
            }
        });
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            value = valueAccessor(),
            objVal = eObj.val();
        if (allBindings().isNumber) {
            objVal = parseInt(objVal);
        }
        if (allBindings().isMultiple && value.indexOf(objVal) > -1 || !allBindings().isMultiple && value() == objVal) {
            eObj.iCheck('check');
        }
        else {
            eObj.iCheck('uncheck');
        }
    }
};

ko.bindingHandlers.translate = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var $item = $(element),
            value = ko.unwrap(valueAccessor()) || {},
            x = ko.unwrap(value.x) || 0,
            y = ko.unwrap(value.y) || 0,
            result = 'translate(' + x + 'px, ' + y + 'px)';

        $item.css('transform', result);
    },

    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var $item = $(element),
            value = ko.unwrap(valueAccessor()) || {},
            x = ko.unwrap(value.x) || 0,
            y = ko.unwrap(value.y) || 0,
            result = 'translate(' + x + 'px, ' + y + 'px)';

        $item.css('transform', result);
    }
}

ko.bindingHandlers.lazyResize = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var $element = $(element);

        var setHeight = function () {
            var height = _($element.children()).reduce(function (r, child) {
                return r + $(child).offsetHeight;
            }, 0);

            $element.css({ height: height });
        }

        var observer = new MutationObserver(setHeight);
        observer.observe(element, { attributes: true, childList: true, subtree: true });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            observer.disconnect();
        });
    }
}

ko.bindingHandlers.fullpage = {
    init: function (element, valuesAccessor, allBindings) {
        var $el = $(element),
            value = ko.unwrap(valuesAccessor()),
            $w = $(window),
            $footer = $("#footer"),
            $header = $("#header");

        var update = _.debounce(function () {
            var topOffset = $el.offset().top,
                bottomOffset = $footer.outerHeight();

            if (value) {
                $el.css({ 'min-height': window.innerHeight - topOffset - bottomOffset });
            }
            else {
                $el.css({ 'height': window.innerHeight - topOffset - bottomOffset });
            }
        }, 0);

        var observer = new MutationObserver(function (mutations) {
            var handle = _(mutations).any(function (m) { return m.target != element && $(m.target).closest(element).length == 0 });

            if (handle) update();
        });

        $w.on('resize', update);

        observer.observe($("body")[0], { attributes: true, childList: true, subtree: true });

        update();

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $w.off('resize', update);
            observer.disconnect();
        });
    }
}

ko.bindingHandlers.slideToggleCSS = (function () {
    var applyTransitions = function ($item) {
        $item.css({
            '-webkit-transition': 'margin .4s ease-in-out',
            '-moz-transition': 'margin .4s ease-in-out',
            '-o-transition': 'margin .4s ease-in-out',
            'transition': 'margin .4s ease-in-out'
        });
    }

    var transitions = {
        "transition": "transitionend",
        "OTransition": "oTransitionEnd",
        "MozTransition": "transitionend",
        "WebkitTransition": "webkitTransitionEnd",
        "MSTransition": "MSTransitionEnd"
    }

    var onTransitionEnd = function (e) {
        $(e.srcElement).parent().css({ 'overflow': 'visible' });
    };

    var func = function (element, valueAccessor) {
        var $item = $(element),
            value = valueAccessor();

        var eventName = _(transitions).find(function (t, key) {
            return element.style[key] !== undefined;
        });

        $item.off('resize');
        $item.off(eventName, onTransitionEnd);

        if (ko.unwrap(value)) {
            if ($item.is(':hidden')) {
                $item.show();
                $item.css({ 'margin-top': -element.scrollHeight + 'px' });

                _.defer(function () {
                    applyTransitions($item);
                });
            }

            _.defer(function () {
                $item.css({ 'margin-top': 0 });

                $item.one(eventName, onTransitionEnd);
            });
        } else {
            $item.parent().css({ 'overflow': 'hidden' });
            $item.css({ 'margin-top': -element.scrollHeight + 'px' });

            $item.on('resize', function () {
                $item.css({ 'margin-top': -element.scrollHeight + 'px' });
            });
        }
    };

    return {
        init: function (element, valueAccessor) {
            var $item = $(element);

            $item.hide();
            func(element, valueAccessor);
        },

        update: func
    }
})();

ko.virtualElements.allowedBindings.wccAfterRender = true;
ko.bindingHandlers.wccAfterRender = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var callback = valueAccessor();

        if (callback) {
            _.defer(function () {
                callback(element);
            });
        }
    }
}

ko.bindingHandlers.animate = {
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            animateObj = valueAccessor(), //all binding properties are encapsulated inside one object for easy syntax
            value = animateObj.valueProperty, //The KO model property this binding is attached to. Must be boolean
            /*  TimeOuts control the timing of the animation trigger. It has two fields:
                1. trueTimeOut: The timeout for the animation when the value is true
                2. falseTimeOut: The timeout for the animation when the value is false  */
            timeOuts = animateObj.timeOuts || {},
            animationProperties = animateObj.animatedProperties,
            animationOptions = animateObj.animationOptions || {
                duration: 300,
                easing: 'swing'
            };

        /*The visibility control handles the visibility of the element. For instance, it is needed in cases where we run the animation over 'Opacity' css property. Any control takes full space on the page 
        if the opacity is 0 and so hiding it is needed in most cases. This is just one example for which we can make another binding that can use jQuery's show/hide functions. But I am using it here for 
        the same as it can also be used for other uses and I don't really see a good reason to add another binding unless any issues or seen. */
        if (animateObj.controlVisibility) {
            animationOptions.start = function () {
                if (value) {
                    eObj.show();
                }
            };
            animationOptions.complete = function () {
                if (!value) {
                    eObj.hide();
                }
            };
        }

        for (var k in animationProperties) { //set multiple properties here
            var propertyValues = animationProperties[k],
                startValue = 0,
                endValue = 0;

            if (typeof propertyValues.endValue == 'function') {
                endValue = propertyValues.endValue();
            }
            else {
                endValue = propertyValues.endValue;
            }

            if (typeof propertyValues.startValue == 'function') {
                startValue = propertyValues.startValue();
            }
            else {
                startValue = propertyValues.startValue;
            }

            if (value) {
                animationProperties[k] = startValue;
            }
            else {
                animationProperties[k] = endValue;
            }
        }

        //Assign the main caller function to a variable so it can be called at multiple places without duplicating the code
        var animateFunc = function () { eObj.stop(true, true).animate(animationProperties, animationOptions); };

        if (value) {
            if (timeOuts.trueTimeOut) { //if true timeOut is set, delay the animation
                setTimeout(animateFunc, timeOuts.trueTimeOut);
            }
            else {
                animateFunc();
            }
        }
        else {
            if (timeOuts.falseTimeOut) { //if false timeOut is set, delay the animation
                setTimeout(animateFunc, timeOuts.falseTimeOut);
            }
            else {
                animateFunc();
            }
        }
    }
};

ko.bindingHandlers.loaded = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var $el = $(element),
            value = valueAccessor();

        if (ko.isObservable(value)) {
            $el.load(function () {
                value(true);
            });
        }
    }
}

ko.bindingHandlers.videoLoaded = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var $el = $(element),
            value = valueAccessor();

        if (ko.isObservable(value)) {
            var action = function () {
                value(true);

                $el.off('loadeddata', action);
            }

            $el.on('loadeddata', action);

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                $el.off('loadeddata', action);
            });
        }
    }
}

ko.bindingHandlers.modalDialog = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        var $el = $(element),
            value = valueAccessor();

        $el.modal(value() ? 'show' : 'hide');
        $el.on('hidden.bs.modal', function (e) {
            value(false);
        })
    }
};

ko.bindingHandlers.selected = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        var selected = ko.utils.unwrapObservable(valueAccessor());
        if (selected) element.select();
    }
};

ko.bindingHandlers.inViewPort = {
    isVisible: function (container, element) {
        if (element == null) return false;

        var w = $(container),
            containerMode = container != window,
            item = $(element);

        var containerBottom = item.is(':last-child') ? w.scrollTop() + w.height() : w.scrollTop() + w.height() - 50,
            containerTop = item.is(':first-child') ? w.scrollTop() : w.scrollTop() + 50,
            itemTop = containerMode ? item.position().top : item.offset().top,
            itemBottom = containerMode ? item.position().top + item.height() : item.offset().top + item.height();

        return itemTop <= containerBottom && itemBottom >= containerTop;
    },

    init: function (element, valueAccessor, allBindingsAccessor) {
        var self = ko.bindingHandlers.inViewPort,
            item = ko.unwrap(allBindingsAccessor.get('inViewPort-element')) || $(element),
            container = ko.unwrap(allBindingsAccessor.get('inViewPort-container')),
            w = container != null ? container : window,
            $w = $(w),
            value = valueAccessor();

        if (ko.observable(value)) {
            const action = system.toUICallback(() => {
                if (self.isVisible(w, item))
                    value(true);
                else
                    value(false);
            });

            $w.on('scroll', action);
            action();

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => $w.off("scroll", action));
        }
    }
};

ko.bindingHandlers.animationComplete = {
    init: function (element, valueAccessor) {
        var item = $(element),
            callback = valueAccessor();

        var animations = {
            "animation": "animationend",
            "OAnimation": "oAnimationEnd",
            "MozAnimation": "animationend",
            "WebkitAnimation": "webkitAnimationEnd",
            "MSAnimation": "MSAnimationEnd"
        }

        var eventName = _(animations).find(function (a, key) {
            return element.style[key] !== undefined;
        });

        if (_(callback).isFunction() && eventName != null) {
            item.on(eventName, callback);

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                item.off(eventName, callback);
            });
        }
    }
}

ko.bindingHandlers.transitionComplete = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var item = $(element),
            callback = valueAccessor(),
            chilrenTransitions = allBindingsAccessor()['transition-children'],
            onlyTargetEvents = chilrenTransitions == null || chilrenTransitions == false;

        var transitions = {
            "transition": "transitionend",
            "OTransition": "oTransitionEnd",
            "MozTransition": "transitionend",
            "WebkitTransition": "webkitTransitionEnd",
            "MSTransition": "MSTransitionEnd"
        }

        var eventName = _(transitions).find(function (t, key) {
            return element.style[key] !== undefined;
        });

        var onEvent = function (e) {
            if (!onlyTargetEvents || e.currentTarget == element) {
                callback();
            }
        }

        if (_(callback).isFunction() && eventName != null) {
            item.on(eventName, onEvent);

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                item.off(eventName, onEvent);
            });
        }
    }
}

ko.bindingHandlers.shiftUp = {
    init: function (element, valueAccessor) {
        var item = $(element),
            w = $(window),
            value = valueAccessor(),
            height = item.outerHeight(true);

        if (ko.unwrap(value) && w.scrollTop() > 0) {
            w.scrollTop(w.scrollTop() + height);

            _.defer(function () {
                w.scrollTop(w.scrollTop() + item.outerHeight(true) - height);
            });
        }
    }
}

ko.bindingHandlers.cropbox = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor(),
            options = ko.unwrap(value);

        value.cropbox = ko.observable(null);

        if (options) {
            value.cropbox($(element).cropbox(options));
        }
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor(),
            options = ko.unwrap(value),
            cropbox = value.cropbox();

        if (cropbox) {
            cropbox.dispose();
        }

        if (options) {
            cropbox = $(element).cropbox(options);

            cropbox.setImageModified(true);
            value.cropbox(cropbox);
        }
    }
}

ko.bindingHandlers.scroll = {
    updating: true,

    init: (element, valueAccessor, allBindingsAccessor) => {
        ko.bindingHandlers.scroll.updating = true;
        
        const scrollableParent = system.findScrollableParent(element);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(window).off("scroll.ko.scrollHandler");
            scrollableParent.off("scroll.ko.scrollHandler");
            ko.bindingHandlers.scroll.updating = false;
        });
    },

    update: (element, valueAccessor, allBindingsAccessor) => {
        const props = allBindingsAccessor().scrollOptions;
        const offset = 200;//props.offset ? props.offset : 100;
        const loadFunc = props.loadFunc;
        const load = ko.utils.unwrapObservable(valueAccessor());

        const scrollableParent = system.findScrollableParent(element);

        if (load) {
            element.style.display = "";
            scrollableParent.on("scroll.ko.scrollHandler", function () {
                const scrollValue = scrollableParent[0].scrollHeight != undefined ?
                    Math.abs(scrollableParent[0].scrollHeight - scrollableParent[0].scrollTop - scrollableParent[0].clientHeight) : // some DOM element has scroll bars 
                    Math.abs($(document).height() - scrollableParent.scrollTop() - scrollableParent.height()); // in case if scroll parent is window itself

                if (scrollValue < offset) {
                    if (self.updating) {
                        loadFunc()
                        self.updating = false;
                    }
                }
                else {
                    self.updating = true;
                }
            });
        }
        else {
            element.style.display = "none";
            $(window).off("scroll.ko.scrollHandler")
            scrollableParent.off("scroll.ko.scrollHandler")
            ko.bindingHandlers.scroll.updating = false;
        }
    }
}

ko.virtualElements.allowedBindings.lazyRender = true;
ko.bindingHandlers.lazyRender = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var children = _(ko.virtualElements.childNodes(element)).clone();

        var val = ko.pureComputed(function () {
            return !!ko.unwrap(valueAccessor());
        });

        ko.virtualElements.emptyNode(element);

        val
            .when()
            .then(function () {
                ko.virtualElements.setDomNodeChildren(element, children);
                ko.applyBindingsToDescendants(bindingContext, element);
            }, new Promise(function (resolve) { ko.utils.domNodeDisposal.addDisposeCallback(element, function () { resolve() }) }));

        return { controlsDescendantBindings: true }
    }
}

ko.virtualElements.allowedBindings.toContext = true;
ko.bindingHandlers.toContext = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        function Context() {
            this.$prevContext = bindingContext;
        }

        Context.prototype = valueAccessor();

        var context = new Context();

        ko.applyBindingsToDescendants(context, element);

        return { controlsDescendantBindings: true }
    }
}

ko.bindingHandlers.autogrow = (function () {
    var init = function (element) {
        var $item = $(element);

        if ($item.next(".autogrow-textarea-mirror").length != 0) return;

        $(element).after('<div class="autogrow-textarea-mirror"></div>');

        var $mirror = $item.next(".autogrow-textarea-mirror"),
            mirror = $mirror[0];

        // Style the mirror
        $mirror.css('display', 'none');
        $mirror.css('wordWrap', 'normal');
        $mirror.css('whiteSpace', 'normal');
        $mirror.css('padding', $item.css('padding'));
        $mirror.css('width', $item.css('width'));
        $mirror.css('fontFamily', $item.css('font-family'));
        $mirror.css('fontSize', $item.css('font-size'));
        $mirror.css('lineHeight', $item.css('line-height'));

        // Style the textarea
        $item.css('overflow', "hidden");

        var onChange = function () {
            mirror.innerHTML = String($item.val()).replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />') + ".";
            $mirror.css('width', $item.css('width'));

            var oldHeight = $item.height(),
                newHeight = Math.ceil($mirror.height());

            if (newHeight != oldHeight)
                $item.height(newHeight);
        };

        $item.keydown(onChange);
        $item.keyup(onChange);
        $item.on('changed', onChange);

        $item.on('autogrow-dispose', function () {
            $item.off('keydown', onChange);
            $item.off('keyup', onChange);
            $item.off('changed', onChange);

            $mirror.remove();
        });

        onChange();
        _.defer(function () {
            onChange();
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $mirror.remove();
        });
    }

    var dispose = function (element) {
        var $item = $(element);

        $item.trigger('autogrow-dispose');
    }

    return {
        init: function (element, valueAccessor) {
            var val = ko.unwrap(valueAccessor());

            if (val) {
                init(element);
            }
        },

        update: function (element, valueAccessor) {
            var val = ko.unwrap(valueAccessor());

            if (val) {
                init(element);
            } else {
                dispose(element);
            }
        }
    }
})();

ko.bindingHandlers.spinner = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var value = ko.unwrap(valueAccessor()),
            spinner = WCC.System.initSpinner(element, allBindingsAccessor().smallSpinner ? { radius: 15, length: 0, width: 3 } : null);
        if (!value) {
            spinner.stop();
        }
        $(element).data('spinner', spinner);
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        var value = ko.unwrap(valueAccessor());
        if (value) {
            $(element).data('spinner').spin(element);
        }
        else {
            $(element).data('spinner').stop();
        }
    }
};
ko.bindingHandlers.log = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        console.log(valueAccessor());
    }
};
ko.bindingHandlers.spectrum = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor(),
            allBindings = allBindingsAccessor(),
            rgbaMode = allBindings['spectrum-rgba'] || false,
            areButtonsShown = allBindings['spectrum-buttons'] === undefined ? true : allBindings['spectrum-buttons'],
            customClasses = allBindings['spectrum-classes'] || '',
            onShow = allBindings['spectrum-show'] || _.noop,
            onHide = allBindings['spectrum-hide'] || _.noop,
            onInit = allBindings['spectrum-init'] || _.noop;


        var format = function (color) { return rgbaMode ? color.toRgbString() : color.toHexString() };

        $(element).spectrum({
            color: "#000",
            showAlpha: rgbaMode,
            showInput: true,
            className: "full-spectrum " + customClasses,
            showInitial: true,
            showPalette: true,
            showSelectionPalette: true,
            clickoutFiresChange: false,
            showButtons: areButtonsShown,
            maxPaletteSize: 10,
            preferredFormat: "hex",
            localStorageKey: "spectrum.demo",

            show: onShow,

            hide: function (color) {
                value(format(color));
                onHide();
            },

            palette: [
                ["rgb(0, 0, 0)", "rgb(67, 67, 67)", "rgb(102, 102, 102)",
                    "rgb(204, 204, 204)", "rgb(217, 217, 217)", "rgb(255, 255, 255)"],
                ["rgb(152, 0, 0)", "rgb(255, 0, 0)", "rgb(255, 153, 0)", "rgb(255, 255, 0)", "rgb(0, 255, 0)",
                    "rgb(0, 255, 255)", "rgb(74, 134, 232)", "rgb(0, 0, 255)", "rgb(153, 0, 255)", "rgb(255, 0, 255)"],
                ["rgb(230, 184, 175)", "rgb(244, 204, 204)", "rgb(252, 229, 205)", "rgb(255, 242, 204)", "rgb(217, 234, 211)",
                    "rgb(208, 224, 227)", "rgb(201, 218, 248)", "rgb(207, 226, 243)", "rgb(217, 210, 233)", "rgb(234, 209, 220)",
                    "rgb(221, 126, 107)", "rgb(234, 153, 153)", "rgb(249, 203, 156)", "rgb(255, 229, 153)", "rgb(182, 215, 168)",
                    "rgb(162, 196, 201)", "rgb(164, 194, 244)", "rgb(159, 197, 232)", "rgb(180, 167, 214)", "rgb(213, 166, 189)",
                    "rgb(204, 65, 37)", "rgb(224, 102, 102)", "rgb(246, 178, 107)", "rgb(255, 217, 102)", "rgb(147, 196, 125)",
                    "rgb(118, 165, 175)", "rgb(109, 158, 235)", "rgb(111, 168, 220)", "rgb(142, 124, 195)", "rgb(194, 123, 160)",
                    "rgb(166, 28, 0)", "rgb(204, 0, 0)", "rgb(230, 145, 56)", "rgb(241, 194, 50)", "rgb(106, 168, 79)",
                    "rgb(69, 129, 142)", "rgb(60, 120, 216)", "rgb(61, 133, 198)", "rgb(103, 78, 167)", "rgb(166, 77, 121)",
                    "rgb(91, 15, 0)", "rgb(102, 0, 0)", "rgb(120, 63, 4)", "rgb(127, 96, 0)", "rgb(39, 78, 19)",
                    "rgb(12, 52, 61)", "rgb(28, 69, 135)", "rgb(7, 55, 99)", "rgb(32, 18, 77)", "rgb(76, 17, 48)"]
            ]
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $(element).spectrum("destroy");
        });

        onInit(element);
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        $(element).spectrum("set", valueAccessor()());
    }
};
ko.bindingHandlers.iCheckDynamic = {
    init: function (element, valueAccessor, allBindings, bindingContext) {
        var eObj = $(element),
            modelItem = bindingContext;
        eObj.on('change', function (event) {
            var thisCheck = $(this);
            if (thisCheck.is(':checked')) {
                var value = valueAccessor(),
                    found = false;
                if (!allBindings().isMultiple || allBindings().isExclusive)
                    value.removeAll();
                $.each(value(), function (i, v) {
                    if (v.profileQuestionChoiceId == modelItem.profileQuestionChoiceId) {
                        found = true;
                        return false;
                    }
                });
                if (!found) {
                    value.push(modelItem);
                }
            }
            else {
                var value = valueAccessor();
                value.remove(modelItem);
            }
        });
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            modelItem = bindingContext.$data,
            selectedChoices = valueAccessor();

        var found = false;
        if (selectedChoices().length > 0) {
            $.each(selectedChoices(), function (i, choice) {
                if (choice != null) {
                    if (choice.profileQuestionChoiceId == modelItem.profileQuestionChoiceId) {
                        eObj.prop('checked', true);
                        found = true;
                        return false;
                    }
                }
            });
        }
        if (!found) {
            eObj.prop('checked', false)
        }
    }
};

ko.bindingHandlers.errorToggle = (function () {
    var process = function (element, valueAccessor) {
        if (!document.body.contains(element))
            return;

        var eObj = $(element),
            value = valueAccessor();

        if (eObj.hasClass('select2')) {
            eObj = eObj.next('.select2-container');
        }

        if (value.error()) {
            var scroll = false;

            if (typeof value.scrollTo != "undefined")
                scroll = value.scrollTo();

            var scrollContainer = ko.unwrap(value.scrollContainer);

            if (scrollContainer != null)
                scrollContainer = $(scrollContainer);

            WCC.System.markControlAsInvalid(eObj, value.error(), scroll, ko.unwrap(value.container), scrollContainer);
        }
        else {
            WCC.System.markControlAsValid(eObj);
        }
    };

    return {
        init: function (element, valueAccessor) {
            var $element = $(element);

            process(element, valueAccessor);

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                WCC.System.markControlAsValid($element);
            });
        },

        update: process
    };
})();

ko.subscribable.fn.trackErrors = function () {
    var self = this;

    this.error = ko.observable('');
    this.scrollTo = ko.observable(false).extend({ notify: 'always' });
    this.hasErrors = ko.pureComputed(function () { return self.error() && self.error().trim() != '' });

    return this;
};

//ko extensions
//wrapper to an observable that requires accept/cancel
ko.reversibleObservable = function (initialValue) {
    //private variables
    var _actualValue = ko.observable(initialValue),
        _oldValue = initialValue;

    //computed observable that we will return
    var result = ko.computed({
        read: function () {
            return _actualValue();
        },
        write: function (newValue) {
            _actualValue(newValue);
        }
    }).extend({ notify: "always" });

    //update old value to latest
    result.commit = function () {
        _oldValue = _actualValue();
    };

    //force to take original
    result.reset = function () {
        _actualValue(_oldValue);
    };

    //check if the value is changed
    result.isChanged = function () {
        return _oldValue != _actualValue();
    };

    result.trackErrors = ko.observable.fn.trackErrors;
    return result;
};

//wrapper for an observable that remembers its previous value
ko.previousValueObservable = function (initialValue) {
    //private variables
    var _previousValue = ko.observable(initialValue);
    var _currentValue = ko.observable(initialValue);
    var result = ko.dependentObservable({
        read: _currentValue,
        write: function (newValue) {
            _currentValue(newValue);
        }
    });

    result.supportsPreviousValue = true;
    result.oIsEditing = ko.observable(false);

    //update previousValue value to our observable, if it is different
    result.updatePreviousValue = function () {
        if (_previousValue() !== _currentValue()) {
            _previousValue(_currentValue());
        }
    };

    //restore previous value to the current value
    result.restorePreviousValue = function () {
        _currentValue.valueHasMutated();
        _currentValue(_previousValue());
    };

    result.previousValue = _previousValue;
    return result;
};

ko.bindingHandlers.dragToggle = {
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            value = valueAccessor();
        try {
            if (ko.unwrap(value)) {
                eObj.draggable('disable');
            }
            else {
                eObj.draggable('enable');
            }
        }
        catch (ex) { }
    }
};

ko.virtualElements.allowedBindings.hideTemplate = true;
ko.bindingHandlers.hideTemplate = {
    init: function () {
        return { controlsDescendantBindings: true }
    }
}

//This binding is used when we want to set a value on the click of an arbitrary HTML element. Used especially in smart dropdowns
ko.bindingHandlers.clickSetValue = {
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            value = valueAccessor(),
            baseObj = value.baseObject == null ? viewModel : value.baseObject,
            noPropagation = value.noPropagation;

        var link = $(eObj);
        setTimeout(function () {
            link.on('click', function (e) {
                var oldVal;

                if (typeof (baseObj[value.property]) == "function") {//if we have a function then its a ko observable
                    oldVal = baseObj[value.property]();
                    baseObj[value.property](value.valueToSet);//pass it the data value
                }
                else {//if its not a function its just a normal property
                    oldVal = baseObj[value.property];
                    baseObj[value.property] = value.valueToSet;//set the value
                }

                if (value.callBack) {
                    baseObj[value.callBack](oldVal, value.valueToSet);
                }

                // if we don't need bubble efect of this click
                if (noPropagation === true) {
                    // Normal browsers
                    if (e.stopPropagation) {
                        e.stopPropagation();
                    }
                    //IE8 and Lower
                    else {
                        e.cancelBubble = true;
                    }
                    if (e.preventDefault) {
                        e.preventDefault();
                    }
                }
            });
        }, 500);
    }
};
ko.bindingHandlers.clickToggleValue = {
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            value = valueAccessor(),
            baseObj = value.baseObject == null ? viewModel : value.baseObject,
            noPropagation = value.noPropagation;

        var link = $(eObj);
        setTimeout(function () {
            link.on('click', function (e) {
                var oldVal, val;

                if (typeof (baseObj[value.property]) == "function") {//if we have a function then its a ko observable
                    oldVal = baseObj[value.property]();
                    baseObj[value.property](!baseObj[value.property]());//pass it the data value
                    val = baseObj[value.property]();
                }
                else {//if its not a function its just a normal property
                    oldVal = baseObj[value.property];
                    baseObj[value.property] = !baseObj[value.property];//set the value
                    val = baseObj[value.property];
                }

                if (value.callBack) {
                    baseObj[value.callBack](oldVal, val);
                }

                // if we don't need bubble efect of this click
                if (noPropagation === true) {
                    // Normal browsers
                    if (e.stopPropagation) {
                        e.stopPropagation();
                    }
                    //IE8 and Lower
                    else {
                        e.cancelBubble = true;
                    }
                    if (e.preventDefault) {
                        e.preventDefault();
                    }
                }
            });
        }, 500);
    }
};

(function () {//binding handler for the ACE Editor. more info: https://github.com/probonogeek/knockout-ace/blob/master/knockout-ace.js
    var instances_by_id = {} // needed for referencing instances during updates.
        , init_id = 0;           // generated id increment storage

    ko.bindingHandlers.ace = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {

            var options = allBindingsAccessor().aceOptions || {};
            var value = ko.utils.unwrapObservable(valueAccessor());

            // Ace attaches to the element by DOM id, so we need to make one for the element if it doesn't have one already.
            if (!element.id) {
                element.id = 'knockout-ace-' + init_id;
                init_id = init_id + 1;
            }

            var editor = ace.edit(element.id);

            if (options.theme) editor.setTheme("ace/theme/" + options.theme);
            if (options.mode) editor.getSession().setMode("ace/mode/" + options.mode);
            if (options.readOnly) editor.setReadOnly(true);
            if (options.wrap) editor.setOption('wrap', options.wrap);
            if (options.hideGutter) editor.setOption('showGutter', false);
            if (options.hidePrintMargin) editor.setOption('showPrintMargin', false)

            editor.setValue(value);
            editor.gotoLine(0);

            editor.getSession().on("change", function (delta) {
                if (ko.isWriteableObservable(valueAccessor())) {
                    valueAccessor()(editor.getValue());
                }
            });

            instances_by_id[element.id] = editor;

            // destroy the editor instance when the element is removed
            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                editor.destroy();
                delete instances_by_id[element.id];
            });
        },
        update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            var value = ko.utils.unwrapObservable(valueAccessor());
            var id = element.id;

            //handle programmatic updates to the observable
            // also makes sure it doesn't update it if it's the same.
            // otherwise, it will reload the instance, causing the cursor to jump.
            if (id !== undefined && id !== '' && instances_by_id.hasOwnProperty(id)) {
                var editor = instances_by_id[id];
                var content = editor.getValue();
                if (content !== value) {
                    editor.setValue(value);
                    editor.gotoLine(0);
                }
            }
        }
    };

    ko.aceEditors = {
        resizeAll: function () {
            for (var id in instances_by_id) {
                if (!instances_by_id.hasOwnProperty(id)) continue;
                var editor = instances_by_id[id];
                editor.resize();
            }
        },
        get: function (id) {
            return instances_by_id[id];
        }
    };
}());

(function () {//binding handler for the SimpleMDE Editor. more info: https://simplemde.com/
    var instances_by_id = {} // needed for referencing instances during updates.
        , init_id = 0;           // generated id increment storage

    ko.bindingHandlers.simpleMDE = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {

            var options = allBindingsAccessor().editorOptions || {};
            var value = ko.utils.unwrapObservable(valueAccessor());

            // Ace attaches to the element by DOM id, so we need to make one for the element if it doesn't have one already.
            if (!element.id) {
                element.id = 'knockout-simpleIDE-' + init_id;
                init_id = init_id + 1;
            }

            var initFunc = function () {
                var simpleMDEOptions = {
                    element: document.getElementById(element.id),
                    autoDownloadFontAwesome: false,
                    status: ["lines", "words"]
                };

                if (options.placeholder) {
                    simpleMDEOptions.placeholder = options.placeholder;
                }

                if (options.status === false) {
                    simpleMDEOptions.status = options.status;
                }

                if (options.customToolbar !== true && options.showOnlyMergedSymbols != null && options.showOnlyMergedSymbols == true) {
                    simpleMDEOptions.toolbar = ['|'];
                }
                else {
                    if (options.customToolbar === true && options.toolbar) {
                        simpleMDEOptions.toolbar = options.toolbar;
                    }
                    else {
                        //default toolbar
                        //we are customising this because SimpleMDE guys have not upgraded their plugin since 7 years
                        //And font-awesome guys have changed some of their icon names foolishly
                        simpleMDEOptions.toolbar = [
                            {
                                name: "bold",
                                action: SimpleMDE.toggleBold,
                                className: "fa fa-bold",
                                title: "Bold"
                            },
                            {
                                name: "italic",
                                action: SimpleMDE.toggleItalic,
                                className: "fa fa-italic",
                                title: "Italic"
                            },
                            {
                                name: "heading",
                                action: SimpleMDE.toggleHeadingSmaller,
                                className: "fa fa-header",
                                title: "Heading"
                            },
                            "|",
                            {
                                name: "quote",
                                action: SimpleMDE.toggleBlockquote,
                                className: "fa fa-quote-left",
                                title: "Quote"
                            },
                            {
                                name: "unordered-list",
                                action: SimpleMDE.toggleUnorderedList,
                                className: "fa fa-list-ul",
                                title: "Generic List"
                            },
                            {
                                name: "ordered-list",
                                action: SimpleMDE.toggleOrderedList,
                                className: "fa fa-list-ol",
                                title: "Numbered List"
                            },
                            "|",
                            {
                                name: "link",
                                action: SimpleMDE.drawLink,
                                className: "fa fa-link",
                                title: "Create Link"
                            },
                            {
                                name: "image",
                                action: SimpleMDE.drawImage,
                                className: "fa fa-image",
                                title: "Insert Image"
                            },
                            "|",
                            {
                                name: "preview",
                                action: SimpleMDE.togglePreview,
                                className: "fa fa-eye no-disable",
                                title: "Toggle Preview"
                            },
                            {
                                name: "side-by-side",
                                action: SimpleMDE.toggleSideBySide,
                                className: "fa fa-columns no-disable no-mobile",
                                title: "Toggle Side by Side"
                            },
                            {
                                name: "fullscreen",
                                action: SimpleMDE.toggleFullScreen,
                                className: "fa fa-arrows-alt no-disable no-mobile",
                                title: "Toggle Fullscreen"
                            },
                            "|",
                            {
                                name: "guide",
                                action: "https://simplemde.com/markdown-guide",
                                className: "fa fa-question-circle",
                                title: "Markdown Guide"
                            },
                        ];
                    }
                }

                var simpleMDE = new SimpleMDE(simpleMDEOptions);
                simpleMDE.value(value);

                simpleMDE.codemirror.on("change", function () {
                    if (ko.isWriteableObservable(valueAccessor())) {
                        valueAccessor()(simpleMDE.value());
                    }
                });

                instances_by_id[element.id] = simpleMDE;

                if (options.customToolbar !== true && options.noMergedSymbols !== true) {

                    if (WCC.getKOData("MergeSymbols") != null && WCC.getKOData("MergeSymbols").length) { //Render out the merge symbol dropdown
                        var mergeSymbolsSelectId = element.id + '-merge-symbols-select',
                            mergeSymbolsSelectHtml = '<select id="' + mergeSymbolsSelectId + '" style="width: 280px"><option value="">-- Select Integration Symbol --</option>{MERGESYMBOLS}</select>';

                        mergeSymbolsSelectHtml = mergeSymbolsSelectHtml.replace("{MERGESYMBOLS}", WCC.getKOData("MergeSymbols").map(function (m) { return "<option>" + m + "</option>"; }).join(''));
                        var mergeSymbolsSelect = $(mergeSymbolsSelectHtml);
                        $(element).next('.editor-toolbar').append(mergeSymbolsSelect);

                        $('#' + mergeSymbolsSelectId).select2().on('change', function () {
                            var thisRef = $(this);

                            if (WCC.isNullOrEmpty(thisRef.val())) {
                                return;
                            }
                            var cm = simpleMDE.codemirror,
                                selectedText = cm.getSelection(),
                                text = selectedText || thisRef.val();

                            cm.replaceSelection(text);
                        });
                    }
                }

                // destroy the editor instance when the element is removed
                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    simpleMDE.toTextArea();
                    simpleMDE = null;
                    delete instances_by_id[element.id];
                });
            };

            if (options.delayInit) {
                setTimeout(function () {
                    initFunc();
                }, options.delayInit);
            }
            else {
                initFunc();
            }
        },
        update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            var value = ko.utils.unwrapObservable(valueAccessor());
            var id = element.id;

            //handle programmatic updates to the observable
            // also makes sure it doesn't update it if it's the same.
            // otherwise, it will reload the instance, causing the cursor to jump.
            if (id !== undefined && id !== '' && instances_by_id.hasOwnProperty(id)) {
                var simpleMDE = instances_by_id[id];
                var content = simpleMDE.value();
                if (content !== value) {
                    simpleMDE.value(value);
                }
            }
        }
    }
}());

ko.bindingHandlers.datePicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        //initialize datepicker with some optional options
        var options = allBindingsAccessor().datepickerOptions || { format: 'mm/dd/yyyy', inline: false };

        //options.autoclose = true;

        //if (allBindingsAccessor().noMonths) {
        //    options.minView = 4;
        //}
        //else if (allBindingsAccessor().noDays) {
        //    options.minView = 3;
        //}
        //else {
        //    options.minView = 2;
        //}
        //options.startView = options.minView;

        $(element).datetimepicker(options);

        //when a user changes the date, update the view model
        //ko.utils.registerEventHandler(element, "dp.change", function (event) {
        //    var value = valueAccessor();
        //    if (ko.isObservable(value)) {
        //        value(event.date);
        //    }
        //});
    },
    update: function (element, valueAccessor) {
        var widget = $(element).data("DateTimePicker");
        //when the view model is updated, update the widget
        if (widget) {
            var date = ko.utils.unwrapObservable(valueAccessor());
            widget.date(date);
        }
    }
};

ko.bindingHandlers.datePicker332 = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        //initialize datepicker with some optional options
        var options = allBindingsAccessor().datepickerOptions || { format: 'DD/MM/YYYY HH:mm', inline: false };
        $(element).datetimepicker(options);

        //when a user changes the date, update the view model
        ko.utils.registerEventHandler(element, "dp.change", function (event) {
            var value = valueAccessor();
            if (ko.isObservable(value)) {
                if (event.date) {
                    value(event.date.toDate());
                }
                else {
                    value(null);
                }
            }
        });
    },
    update: function (element, valueAccessor) {
        var widget = $(element).data("DateTimePicker");
        //when the view model is updated, update the widget
        if (widget) {
            var date = ko.utils.unwrapObservable(valueAccessor());
            widget.date(date);
        }
    }
};

//This binding is used with time selection control
ko.bindingHandlers.timeSelection = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var timeSelectionOptions = allBindingsAccessor().timeSelectionOptions,
            value = ko.utils.unwrapObservable(valueAccessor()),
            pOptions = {
                'timeFormat': 'H:i',
                'lang': {
                    mins: WCC.Labels.MinutesShort,
                    hr: WCC.Labels.HourShort,
                    hrs: WCC.Labels.HoursShort
                }
            };

        if (timeSelectionOptions != null) {
            if (timeSelectionOptions.show2400) {
                pOptions.show2400 = true;
            }
            if (timeSelectionOptions.step) { //Time selection control doesn't work well with null values, hence copy the attributes if not null
                pOptions.step = timeSelectionOptions.step;
            }
            if (timeSelectionOptions.minTime) {
                pOptions.minTime = timeSelectionOptions.minTime
            }
            if (timeSelectionOptions.maxTime) {
                pOptions.maxTime = timeSelectionOptions.maxTime
            }
            else {
                if (pOptions.show2400) {
                    pOptions.maxTime = "24:00";
                }
                else {
                    pOptions.maxTime = "23:59"; //This will automatically round off to the nearest time depending on the allowed range
                }
            }
            if (timeSelectionOptions.showDuration) {
                pOptions.showDuration = timeSelectionOptions.showDuration;
            }
        }

        $(element).timepicker(pOptions);

        if (WCC.isNotNullOrEmpty(value)) {
            $(element).timepicker('setTime', value);
        }

        $(element).on('change', function () {
            valueAccessor()($(this).val());
        });

        $(element).on('changeTime', function () {
            valueAccessor()($(this).val());
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = ko.utils.unwrapObservable(valueAccessor());

        if (WCC.isNotNullOrEmpty(value)) {
            $(element).timepicker('setTime', value);
        }
        else {
            $(element).timepicker('setTime', null);
        }
    }
}

ko.bindingHandlers.sparkline = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var $element = $(element),
            $window = $(window);

        var value = valueAccessor(),
            options = allBindingsAccessor()['sparkline-options'] || {};

        var build = function () {
            WCC.System.initSparkline($element, ko.unwrap(value), ko.unwrap(options));
        };

        var deferredBuild = _.debounce(build, 250);

        var processor = ko.computed(build).extend({ rateLimit: 250 });

        $window.on('resize', deferredBuild);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            processor.dispose();
            $window.off('resize', deferredBuild);
        });
    }
};

//This binding is used with time selection control
ko.bindingHandlers.highcharts = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = valueAccessor(),
            config = value.highchartsOptions;

        var processor = ko.computed(function () {
            var options = {
                chart: {
                    type: ko.unwrap(config.chartType)
                },
                title: {
                    text: ko.unwrap(config.title),
                    y: 25,
                    widthAdjust: -60
                },
                xAxis: {
                    categories: ko.unwrap(config.categories)
                },
                yAxis: {
                    min: 0,
                    labels: {
                        overflow: 'justify'
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            align: 'left'
                        }
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                    x: -40,
                    y: 80,
                    floating: true,
                    borderWidth: 1,
                    backgroundColor: Highcharts.theme && Highcharts.theme.legendBackgroundColor || '#FFFFFF',
                    shadow: true
                },
                series: [{
                    showInLegend: false,
                    data: ko.unwrap(config.data)
                }],
                tooltip: ko.unwrap(config.tooltip) || {}
            };

            $(function () {
                $(element).highcharts(options);
            });
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            processor.dispose();
        });
    }
};

//This binding is used with IntlTelInput
ko.bindingHandlers.intlTelInput = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            value = valueAccessor();

        var telOptions = {
            autoFormat: true,
            autoPlaceholder: false,
            numberType: 'MOBILE',
            initialCountry: 'auto',
            preferredCountries: ['au', 'gb', 'us']
        };

        $.extend(telOptions, value.options || {});

        eObj.intlTelInput(telOptions);
        
        var currentValue = viewModel[value.value]();

        if (WCC.isNotNullOrEmpty(currentValue)) {
            eObj.intlTelInput('setNumber', currentValue);
            if (viewModel != null && value.isValid != null) {
                viewModel[value.isValid] = eObj.intlTelInput("isValidNumber");                
            }
        }
    },

    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            value = valueAccessor();
        
        eObj.blur(function () {
            if ($.trim(eObj.val())) {                
                viewModel[value.isValid] = eObj.intlTelInput("isValidNumber");
                viewModel[value.value](eObj.intlTelInput("getNumber"));
                
                if (value.numberChanged != null) {
                    viewModel[value.numberChanged]();
                }
            }
            else {
                viewModel[value.value]('');
            }
        });
    }
};

//the binding handler for our Ideation Topic 
ko.bindingHandlers.ideationTopicCanvas = {
    init: function (element, valueAccessor, allBinding, viewModel, bindingContext) {
        //var data = valueAccessor()



        //var drawIdeationData = function () {
        //    if (element != null && element.getContext) {
        //        //numeric seconds since midnight 1970 (UNIX timestamps)
        //        var ideaCreationRangeAfter = data.ideaCreationRangeAfter().getTime();
        //        var ideaCreationRangeBefore = data.ideaCreationRangeBefore().getTime();
        //        var ideaRankingRangeAfter = data.ideaRankingRangeAfter().getTime();
        //        var ideaRankingRangeBefore = data.ideaRankingRangeBefore().getTime();

        //        element.width = element.clientWidth;
        //        element.height = element.clientHeight;

        //        if (element.clientWidth < 50) return;//less than 50px wide don't draw the chart thing

        //        var ctx = element.getContext('2d');
        //        var radius = 6;
        //        var h = 20;//height of all our lines and center of all the circles
        //        var c1 = 10;//position of circle 1 - 10px in from the left edge
        //        var c2 = element.clientWidth / 2;//position of circle 2 - centre of the box
        //        var c3 = element.clientWidth - 10;//position of circle 3 - 10 px in from the right edge
        //        var line1Length = c2 - c1 - radius - radius;
        //        var line2Length = c3 - c2 - radius - radius;
        //        var fullCircle = 2 * Math.PI;
        //        var creationText = WCC.getSetting('IdeaCreationText'); //'Idea Creation';
        //        var rankingText = WCC.getSetting('IdeaRankingText');//'Idea Ranking';
        //        var reviewText = WCC.getSetting('IdeaReviewText');//Idea Review
        //        var resultsText = WCC.getSetting('IdeaResultsText'); //Idea Results
        //        var textToDraw = 0;//0-Creation, 1-Review, 2-Review

        //        //draw black background
        //        ctx.beginPath();//start drawing
        //        ctx.lineWidth = 3;//make it a fat line
        //        ctx.strokeStyle = '#BFBFBF';
        //        ctx.arc(c1, h, radius, 0, fullCircle, false);//start with a full circle, leaves cursor 10+radius,10
        //        ctx.lineTo(c2 - radius, h);//draw a straight line from the right edge of circle 1 to the left edge of circle 2
        //        ctx.moveTo(c2 + radius, h);//move over the circle so we don't fill in the middle with a line
        //        ctx.arc(c2, h, radius, 0, fullCircle, false);//draw circle 2, start and finish at 3 o'clock
        //        ctx.lineTo(c3 - radius, h);//draw a straight line from the right edge of circle 2 to the left edge of circle 3
        //        ctx.moveTo(c3 + radius, h);//move over circle 3 to start drawing at the right edge
        //        ctx.arc(c3, h, radius, 0, fullCircle, false);//draw circle 3
        //        ctx.stroke();//commit

        //        //now we can kick off some kind of animation or something for drawing where we are up to
        //        //for now we just draw it out
        //        var now = new Date().getTime();

        //        if (now > ideaCreationRangeAfter) {//we are after the create start idea - draw our first circle all green
        //            ctx.beginPath();//start drawing coloured stuff
        //            ctx.lineWidth = 3;//make it a fat line
        //            ctx.strokeStyle = '#00F81D';//use our green colour

        //            ctx.moveTo(c1 + radius, h);//move to first circle
        //            ctx.arc(c1, h, radius, 0, fullCircle, false);//draw it in full in green

        //            if (ideaCreationRangeBefore > now) {//we are in the Creation period so we just want to draw our line
        //                //work out how far we are in
        //                var range = ideaCreationRangeBefore - ideaCreationRangeAfter;
        //                var nowRange = now - ideaCreationRangeAfter;

        //                var percent = (nowRange / range);

        //                ctx.moveTo(c1 + radius, h);
        //                ctx.lineTo(c1 + radius + (line1Length * percent), h);
        //                textToDraw = 0;//creation stage
        //            }
        //            else {//we are past the close of the Create period
        //                //just because we are past the Create Before date doesn't mean we are into the ranking stage.
        //                //draw our first line in full in green.  we definitely need the full first line in green
        //                ctx.moveTo(c1 + radius, h);//first circle, right edge
        //                ctx.lineTo(c1 + radius + line1Length, h);
        //                textToDraw = 1;//review stage

        //                if (now > ideaRankingRangeAfter) {//we are into the ranking stage                                
        //                    //middle circle is green
        //                    ctx.moveTo(c2 + radius, h);//move so we don't draw
        //                    ctx.arc(c2, h, radius, 0, fullCircle, false);//draw circle 2 fully in green

        //                    if (now > ideaRankingRangeBefore) {//we are done: 
        //                        textToDraw = 3;//ranking stage
        //                        //second line fully in green
        //                        ctx.moveTo(c2 + radius, h);
        //                        ctx.lineTo(c3 - radius, h);

        //                        //draw the last circle green as well.
        //                        ctx.moveTo(c3 + radius, h);
        //                        ctx.arc(c3, h, radius, 0, fullCircle, false);
        //                    }
        //                    else {//we are in the middle of the ranking stage
        //                        //work out how far to draw the line
        //                        textToDraw = 2;//ranking stage
        //                        var range = ideaRankingRangeBefore - ideaRankingRangeAfter;
        //                        var nowRange = now - ideaRankingRangeAfter;

        //                        var percent = (nowRange / range);

        //                        ctx.moveTo(c2 + radius, h);
        //                        ctx.lineTo(c2 + radius + (line2Length * percent), h);
        //                    }
        //                }
        //            }

        //            ctx.stroke();//commit coloured line

        //            //now draw our text, we only draw one text string and position it over the stage
        //            ctx.font = "14px Helvetica Neue";
        //            ctx.fillStyle = '#000000;';
        //            ctx.shadowOffsetX = 0;
        //            ctx.shadowOffsetY = 0;
        //            ctx.shadowBlur = 0;

        //            if (textToDraw == 0) {//draw 'Idea Creation' on the left line
        //                var textWidth = ctx.measureText(creationText).width;
        //                ctx.fillText(creationText, c1 + (line1Length / 2) - (textWidth / 2), 10);
        //            } else if (textToDraw == 1) {//draw 'Idea Review' over the middle circle
        //                var textWidth = ctx.measureText(reviewText).width;
        //                ctx.fillText(reviewText, (element.clientWidth / 2) - (textWidth / 2), 10);
        //            } else if (textToDraw == 2) {//draw 'Idea Ranking' over the right line
        //                var textWidth = ctx.measureText(rankingText).width;
        //                ctx.fillText(rankingText, c2 + (line2Length / 2) - (textWidth / 2), 10);
        //            } else if (textToDraw == 3) {
        //                var textWidth = ctx.measureText(resultsText).width;
        //                ctx.fillText(resultsText, c2 + (line2Length / 2) - (textWidth / 2), 10);
        //            }
        //        }

        //        window.setTimeout(drawIdeationData, 1000);//draw every second
        //    }//if the element is null we drop out and don't add the timeout again
        //};

        //drawIdeationData();//start it off

        //$(window).on('resize', function () {//also add an on-resize handle
        //    drawIdeationData();
        //});
    }
};

//Extend ko objects with underscore functions
_.extend(ko.subscribable.fn, _.chain(_.prototype).keys().filter(function (key) {
    //add function only if key in fn array is free
    //fn - array of ko objec methods
    return ko.subscribable.fn[key] == null;
}).map(function (key) {
    var func = function () {
        var observable = this;

        var args = _(arguments).toArray();

        var result = ko.pureComputed(function () {
            var w = _(observable());
            return w[key].apply(w, args);
        });

        return result;
    }

    return [key, func];
}).object().value());

var map = function (predicate, context) {
    predicate = predicate || _.identity;

    //var id = _.uniqueId('map-');

    var observable = this,
        buffer = ko.observableArray([]),
        result = ko.observableArray([]).extend({ deferred: true });

    var processChanges = function (changes) {
        //console.log(id, 'changes', changes);

        var oldResult = _.clone(result()),
            newResult = _.clone(result()),
            added = [],
            removed = [];

        _(changes).each(function (change) {
            if (change.status === 'added') {
                if (change.moved !== undefined) {
                    added.push({ index: change.index, value: oldResult[change.moved] });
                    removed.push({ index: change.moved });
                } else {
                    added.push({ index: change.index, value: predicate.call(context, change.value, change.index) });
                }
            } else if (change.status === 'deleted' && change.moved === undefined) {
                removed.push({ index: change.index });
            }
        });

        _.chain(removed)
            .sortBy(function (change) { return -change.index })
            .each(function (change) { newResult.splice(change.index, 1) });

        _.chain(added)
            .sortBy('index')
            .each(function (change) { newResult.splice(change.index, 0, change.value) });

        result(newResult);

        //console.log('final result', result());
        //console.log('isValid', buffer().length === result().length);
    };

    return ko.pureComputed(function () {
        ko.ignoreDependencies(function (newList) {
            var oldList = buffer();

            buffer(_(newList).clone());

            //console.log('old', oldList);
            //console.log('new', newList);
            //console.log('result', result());

            var changes = ko.utils.compareArrays(oldList, newList, { 'sparse': true });
            processChanges(changes);
        }, null, [observable()]);

        return ko.ignoreDependencies(function () {
            return _.clone(result());
        });
    }, this);
};



var not = function () {
    var observable = this;

    return ko.pureComputed(function () { return !observable() });
};

ko.subscribable.fn.map = map;

ko.observableArray.fn.map = map;
ko.subscribable.fn.not = not;

ko.observableArray.fn.concat = function (arr) {
    var observable = this;

    return ko.pureComputed(function () {
        return _(observable()).concat(ko.unwrap(arr));
    });
};

ko.subscribable.fn.each = function () {
    var observable = this,
        args = _(arguments).toArray();

    var result = ko.computed(function () {
        var w = _(observable());
        return w.each.apply(w, args);
    });

    return result;
};

ko.subscribable.fn.toPOJO = function () {
    return ko.unwrap(this);
}

var guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
ko.subscribable.fn.isGuid = function () {
    var observable = this;

    return ko.pureComputed(function () {
        var v = observable();

        return _(v).isString() && guidRegex.test(v);
    });
}

ko.extenders.hasErrors = function (target) {
    target.error = ko.observable(null);
    target.isValid = target.error.mapSingle(function (err) { return err == null });
    target.hasError = target.isValid.not();
    target.scrollTo = ko.observable(false);
}

ko.extenders.moment = function (target) {
    //create a writable computed observable to intercept writes to our observable
    var result = ko.pureComputed({
        read: target,  //always return the original observables value
        write: function (newValue) {
            var current = target(),
                valueToWrite = moment(newValue);

            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            }
        }
    }).extend({ notify: 'always' });

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
};

ko.subscribable.fn.shortcut = function (length, hard, fullwords) {
    const observable = this;

    return ko.pureComputed(() => {
        const content = observable();

        if (_.isString(content))
            return TextHelpers.shortcut(content, length, hard, fullwords);
        else
            return '';
    });
}

ko.groups = function () {
    var groups = {}

    var Group = function (name) {
        var self = this,
            items = [];

        self.items = function () {
            var addItem = function (item) {
                if (!_(items).contains(item)) {
                    items.push(item);
                }
            }

            return {
                add: addItem
            }
        }();

        self.dispose = function () {
            _(items).each(function (i) {
                if (i.dispose != null) { i.dispose(); }
            });

            items = [];
        }
    }

    var getGroup = function (name, createIfNotExists) {
        var createIfNotExists = createIfNotExists != null ? createIfNotExists : true;

        if (groups[name] == null && createIfNotExists) {
            groups[name] = new Group(name);
        }

        return groups[name];
    }

    return {
        get: getGroup
    }
}();

ko.utils.disposeGroup = function (name) {
    var group = ko.groups.get(name, false);

    if (group != null) {
        group.dispose();
    }
}

var _subscribe = ko.subscribable.fn.subscribe;
ko.subscribable.fn.subscribe = function () {
    var s = _subscribe.apply(this, arguments);

    s.register = function (name) {
        if (_(name).isArray()) {
            name.push(s);
        } else {
            ko.groups.get(name).items.add(s);
        }

        return this;
    }

    return s;
}

ko.subscribable.fn.register = function (name) {
    if (_(name).isArray()) {
        name.push(this);
    } else {
        ko.groups.get(name).items.add(this);
    }

    return this;
}

ko.subscribable.fn.toUrl = function () {
    var observable = this;

    return ko.pureComputed(function () {
        var val = observable();

        return val ? 'url("' + val + '")' : '';
    });
}

ko.bindingHandlers.select2 = {
    //
    // NOTE: you must use at least select2-4.0.5 for this binding
    //
    init: function (el, valueAccessor, allBindingsAccessor, viewModel) {
        ko.utils.domNodeDisposal.addDisposeCallback(el, function () {
            $(el).select2('destroy');
        });

        var allBindings = allBindingsAccessor(),
            select2 = ko.utils.unwrapObservable(allBindings.select2),
            select2Events = ko.utils.unwrapObservable(allBindings.select2Events);

        var elObj = $(el);

        if (allBindings.defaultItems && allBindings.defaultItems.length > 0) {
            //console.log(allBindings.defaultItems);
            _.each(allBindings.defaultItems, function (data) {
                elObj.append(new Option(data, data, true, true));
            });
            //elObj.trigger('change');
        }

        elObj.select2(select2).on('select2:unselecting', e => {
            //e.preventDefault();
            _.defer(() => elObj.select2('close'))
        });
        
        if (select2Events != null) {
            for (const event in select2Events) {
                elObj.on(event, select2Events[event]); 
            }
        }
    },
    update: function (el, valueAccessor, allBindingsAccessor, viewModel) {
        var allBindings = allBindingsAccessor();

        if ("value" in allBindings) {
            if (allBindings.select2.multiple && allBindings.value() && allBindings.value().constructor != Array) {
                $(el).val(allBindings.value().split(","));
            }
            else {
                $(el).val(allBindings.value());
            }
            $(el).trigger('change');
        } else if ("selectedOptions" in allBindings) {
            var converted = [];
            //var textAccessor = function (value) { return value; };
            //if ("optionsText" in allBindings) {
            //    textAccessor = function (value) {
            //        var valueAccessor = function (item) { return item; }
            //        if ("optionsValue" in allBindings) {
            //            valueAccessor = function (item) { return item[allBindings.optionsValue]; }
            //        }
            //        var items = $.grep(allBindings.options(), function (e) { return valueAccessor(e) == value });
            //        if (items.length == 0 || items.length > 1) {
            //            return "UNKNOWN";
            //        }
            //        return items[0][allBindings.optionsText];
            //    }
            //}
            $.each(allBindings.selectedOptions(), function (key, value) {
                converted.push(value);
            });

            $(el).val(converted);
            $(el).trigger('change');
        }
    }
};

ko.bindingHandlers.ChoiceQuestionOptions = {
    processNewChoices: function (value) {
        //new magic now supports inserting choices.
        var numberValuesNeeded = false;

        return value.split('\n').map(function (s, i) {

            if (!s || s.length <= 0) {
                return null;
            }

            var ret = {};

            if (s.indexOf('[') > -1 && s.indexOf(']') > -1) {
                var bStart = s.indexOf('['),
                    bEnd = s.indexOf(']');
                if (bEnd - bStart > 1) {
                    ret.choiceImage = s.substr(bStart + 1, bEnd - bStart - 1);
                    s = s.replace('[' + ret.choiceImage + ']', '');
                }
            }

            var parts = s.split('||');
            var sLower = s.toLowerCase();

            //these two should really always be there and they MUST be first
            if (parts.length == 1) {
                ret.choiceText = parts[0];
            }
            else if (parts.length > 1) {
                var startsWithOldValueMatches = /^[\d]+\|\|/.exec(s); //if we are editing choices, the old value must be there in the beginning

                if (startsWithOldValueMatches != null && startsWithOldValueMatches.length >= 1) {
                    ret.oldValue = parts[0];
                    ret.choiceText = parts[1];
                }
                else {
                    ret.choiceText = parts[0];
                }
            }
            else {
                ret.choiceText = '';
            }

            var valueMatches = /\(?(\d+)\)/g.exec(ret.choiceText);

            if (valueMatches != null && valueMatches.length >= 2) {
                ret.choiceValue = valueMatches[1];

                if (isNaN(ret.choiceValue)) {
                    throw "The data contains invalid choice values. Choices values must be numeric";
                    return;
                }

                ret.choiceText = ret.choiceText.replace("(" + ret.choiceValue + ")", ""); //strip number value

                if (i == 0) { //if it's first value, set validNumberValues to true
                    numberValuesNeeded = true;
                }
            }
            else {
                ret.choiceValue = i + 1;

                if (i == 0) { //if it's first value, set validNumberValues to true
                    numberValuesNeeded = false;
                }
            }

            if (i > 0) {
                if ((numberValuesNeeded == true && (valueMatches == null || valueMatches.length == 0)) || (numberValuesNeeded == false && (valueMatches != null && valueMatches.length > 0))) {
                    throw "You can't have some choices with values and some without values";
                    return;
                }
            }

            //now check the other options
            ret.isExclusive = /((?!\d+)[^||]+)\|\|exclusive/.test(sLower); //Make sure that "3||Exclusive choice' is not treated as an exclusive type and only '3||Exclusive choice||exclusive' is treated as an exclusive choice
            ret.isDefault = /((?!\d+)[^||]+)\|\|default/.test(sLower); //Make sure that "3||Default choice' is not treated as default choice type and only '3||Default choice||default' is treated as a default choice
            ret.isHidden = /((?!\d+)[^||]+)\|\|hidden/.test(sLower); //Make sure that "3||Hidden choice' is not treated as hidden choice type and only '3||Hidden choice||other' is treated as a hidden choice
            ret.isPinned = /((?!\d+)[^||]+)\|\|pinned/.test(sLower); //Make sure that "3||Pinned choice' is not treated as pinned choice type and only '3||Pinned choice||pinned' is treated as pinned choice
            ret.isNA = /((?!\d+)[^||]+)\|\|notapplicable/.test(sLower); //Make sure that "3||Choice Text' is not treated as na choice type and only '3||Choice Text||NotApplicable' is treated as na choice
            ret.isOther = /((?!\d+)[^||]+)\|\|other/.test(sLower); //Make sure that "3||other choice' is not treated as other choice type and only '3||other choice||other' is treated as other choice

            if (ret.choiceText.indexOf('::') != -1 && WCC.Tag) { //we have tag here
                var isAppliedOnThread = ret.choiceText.indexOf(':::') != -1; //':::' means the tag is applied both on person as well as thread

                var choiceTextParts = ret.choiceText.split(isAppliedOnThread ? ':::' : '::');
                ret.choiceText = choiceTextParts.length >= 1 ? choiceTextParts[0] : '';
                ret.dataTag = {
                    TagValue: choiceTextParts[1],
                    Visibility: WCC.Enums.ContentTagVisibilityType.AdministratorsOnly.value,
                    SpecialType: isAppliedOnThread ? WCC.Enums.ContentTagSpecialType.ChoiceTagForThread.value : WCC.Enums.ContentTagSpecialType.None.value
                };
            }
            ret.choiceText = WCC.System.generateStringWithBasicFormattingTags(ret.choiceText); //strip html other than bold, italics and underlines
            return ret;
        }).filter(function (i) { return i != null; });//clean out any blank lines
    },

    init: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
        $(element).on('blur', function () {
            var allBindings = allBindingsAccessor();
            if (viewModel.showChoicesInTextArea != null) {
                viewModel.showChoicesInTextArea = false;
            }
            allBindings.ChoicesError(false);
            allBindings.ChoicesError.error('');

            var processedChoices = [];//these are the new choices in order that have been processed and should replace whatever we originally had in our question choices array

            //old/original choices as an array:
            var oldChoices = context.$data.choices();

            try {
                //turn whatever they typed in into a nice object            
                var newChoices = ko.bindingHandlers.ChoiceQuestionOptions.processNewChoices(element.value);
            }
            catch (err) {
                allBindings.ChoicesError(true);
                allBindings.ChoicesError.error(err);
                return;
            }

            //go through everything they entered in one by one and match it up with what we used to have OR create a new one
            for (var iChoice = 0; iChoice < newChoices.length; iChoice++) {
                var c = newChoices[iChoice];

                //update their choice order to their new index/position
                c.choiceOrder = iChoice;//keep this starting at 0 cause you always send them all, no need to be tricky

                //check to see if there was already a choice with a matching value (compare against my old choice value in case it is being changed now)
                var originalChoice = $.grep(oldChoices, function (e) { return e.choiceValue == c.oldValue; });

                if (originalChoice && originalChoice.length == 1) {//we found one that matches
                    originalChoice = originalChoice[0];//get our choice out.  we really only want this for its id because we are updating it.

                    c.profileQuestionChoiceId = originalChoice.profileQuestionChoiceId;//steal its id
                }

                c = new ApiProfileQuestionChoice(c, context.$data);

                if (c.choiceValue === null) {//no choice value, make sure this stays NULL after 
                    c.choiceValue = null;
                }

                processedChoices.push(c);
            }

            if (processedChoices && processedChoices.length > 0) {
                //now process any NEW choice values if they have not been set somehow (either by the user or by linking to existing choices as an update)

                //first get the biggest numeric value we have
                var nextChoiceValue = processedChoices.map(function (c) { return parseInt(c.choiceValue, 10); }).filter(function (c) { return !isNaN(parseInt(c, 10)); }).sort();

                if (nextChoiceValue && nextChoiceValue.length > 0) {//we found at least one value that is real
                    nextChoiceValue = nextChoiceValue[nextChoiceValue.length - 1] + 1;
                }
                else {
                    nextChoiceValue = 1;//we didnt find any values, they must ALL be new, start our values at 1
                }

                //now go  through each question and set their value if they dont have one
                for (var iChoice = 0; iChoice < processedChoices.length; iChoice++) {
                    var c = processedChoices[iChoice];

                    if (c.choiceValue === null) {//choice value SHOULD be a number, if it is empty string then then are trying to add a new choice and didnt give it a value, fix it
                        c.choiceValue = nextChoiceValue;
                        nextChoiceValue++;
                    }
                }
            }

            //now replace the choices array with this new processed one and we are done.
            context.$data.choices(processedChoices);//pass this new value (array) into the observable array to swap all its elements                
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        if (viewModel.showChoicesInTextArea != null && viewModel.showChoicesInTextArea == false) { //don't update choices live in the editor
            viewModel.showChoicesInTextArea = true;
        }
        else {
            var choices = ko.unwrap(valueAccessor());//get our data (get the value not the observable)

            //process each choice turning it into a line of text.
            //add them all up
            //stuff them in the text box (element)
            if ($.isArray(choices)) {
                var newText = choices.map(function (i) {
                    return i.toUserEditString();
                }).join('\n');

                $(element).val(newText);
            }
        }
    }
};

ko.bindingHandlers.limitCharacters = {
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        element.value = element.value.substr(0, valueAccessor());
        allBindingsAccessor().value(element.value.substr(0, valueAccessor()));
    }
};

ko.bindingHandlers.enterkey = {
    init: function (element, valueAccessor, allBindings, viewModel) {
        var callback = valueAccessor();
        $(element).keypress(function (event) {
            var keyCode = (event.which ? event.which : event.keyCode);
            if (keyCode === 13) {
                callback.call(viewModel);
                return false;
            }
            return true;
        });
    }
};

ko.bindingHandlers.maskedInput = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            options = allBindings().maskOptions || {},
            mask = options.mask || '';

        eObj.on('blur', function (event) {
            valueAccessor()(eObj.val()/*.replace('$ ', '')*/);
        });
        eObj.inputmask(mask);
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            value = valueAccessor();
        eObj.val(value());
    }
};

ko.extenders.numeric = function (target, options) {
    //create a writable computed observable to intercept writes to our observable
    var result = ko.pureComputed({
        read: target,  //always return the original observables value
        write: function (newValue) {
            var current = target(),
                roundingMultiplier = Math.pow(10, options.precision),
                newValueAsNum = isNaN(newValue) ? 0 : +newValue,
                valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;

            if (typeof options.min != 'undefined' && valueToWrite < options.min) {
                valueToWrite = options.min;
            }
            if (typeof options.max != 'undefined' && valueToWrite > options.max) {
                valueToWrite = options.max;
            }

            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                //if the rounded value is the same, but a different value was written, force a notification for the current field
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    }).extend({ notify: 'always' });

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
};

ko.bindingHandlers.marked = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        var allBindings = allBindingsAccessor();
        var markdownBinding = allBindings.marked;

        var markdownData = ko.unwrap(markdownBinding);
        element.innerHTML = marked(markdownData);

        $(element).find('a').attr({ 'target': '_blank' });
    }
};

ko.bindingHandlers.timeCircles = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            options = allBindings().timeCircles.timerOptions || {}

        var defaultOptions = {
            count_past_zero: false,
            time: {
                Days: { show: false },
                Hours: { show: false },
                Minutes: { show: false },
                Seconds: {
                    show: true,
                    text: ""
                }
            }
        };

        $.extend(defaultOptions, options);
        eObj.TimeCircles(defaultOptions);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            eObj.TimeCircles().destroy();
        });
    }
};