import { componentMasterMetadataKey } from 'decorators/componentMaster';

export default class WCCComponentMasterLoader {
    load(ctor: any) {
        const masterIDs: Array<string> = Reflect.getMetadata(componentMasterMetadataKey, ctor) ?? [];

        if (masterIDs.length > 0) {
            const masters = masterIDs.map(masterId => ({ masterId, content: $(`#${masterId}`).html() }));

            return masters.reduce((result, { masterId, content }) => {
                if (result != undefined) {
                    if (content != undefined) {
                        return result.replace('<!-- ko template: $masterBody --><!-- /ko -->', content);
                    } else {
                        console.error(`can't load master content from ${masterId}`);
                        return undefined;
                    }
                }

                return result;
            }, <string | undefined>'<!-- ko template: $masterBody --><!-- /ko -->');
        }
    }
}