import { DOMHelpers } from 'helpers/dom';
import ko, { Subscribable, SubscribableOrNullableValue } from 'knockout';
import { NodeTracker } from 'managers/nodes/tracker';
import { ValidatableSubscribable } from '../extenders/validate';
import { TooltipPlacement } from './tooltip';

interface Tooltip {
    visible: Subscribable<boolean>
    element: HTMLElement
}

const documentTracker = new NodeTracker({ node: document.body });
const tooltips = ko.observableArray<Tooltip>();

const topTooltip = ko.pureComputed(() => {
    var visibleTooltips = tooltips().filter(tooltip => tooltip.visible());

    if (visibleTooltips.length > 0) {
        documentTracker.mutations();

        var result = visibleTooltips.reduce((result, tooltip) => {
            if (tooltip.visible()) {
                const { top, height } = tooltip.element.getBoundingClientRect();

                if (height > 0 && (result.top == undefined || top < result.top))
                    return { tooltip, top };
            }

            return result;
        }, { tooltip: <Tooltip | undefined>undefined, top: <number | undefined>undefined });

        return result.tooltip;
    }
}).extend({ notifyIfChanged: true });

ko.computed(() => {
    const tooltip = topTooltip();

    if (tooltip != undefined)
        DOMHelpers.scrollToDelayed(tooltip.element);
});

export interface ValidationTooltipConfig {
    field?: ValidatableSubscribable<any>
    error?: SubscribableOrNullableValue<string>
    placement?: SubscribableOrNullableValue<TooltipPlacement>
    enable?: Subscribable<boolean>
    container?: SubscribableOrNullableValue<string | HTMLElement>
}

ko.bindingHandlers.validationTooltip = {
    init: function (element, valueAccessor, ...args) {
        const value = <ValidationTooltipConfig>valueAccessor();
        const field = <ValidatableSubscribable<any> | undefined>value.field;
        const fieldError = ko.flattenComputed(field?.error, '');
        const error = ko.flattenComputed(value.error, fieldError);
        const hasError = error.is(err => err.length > 0);
        const enable = ko.flattenComputed<boolean>(value.enable, true);
        const placement = ko.flattenComputed(value.placement);
        const container = ko.flattenComputed<string | HTMLElement>(value.container);

        const visible = ko.pureComputed(() => hasError() && enable());

        const tooltip = <Tooltip>{
            visible: visible,
            element: element
        }

        tooltips.push(tooltip);

        const valueWrapper = ko.pureComputed(() => ({
            message: error,
            visible: visible,
            placement: placement,
            container: container,
            force: true
        }));

        ko.bindingHandlers.tooltip?.init?.(element, valueWrapper, ...args);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => tooltips.remove(tooltip));
    }
}