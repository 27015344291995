import { Func } from 'interfaces/func';
import ko, { UnwrappedTuple } from 'knockout';

ko.strictComputed = function <T extends Array<any>, U>(factory: Func<U, UnwrappedTuple<[...T]>>, deps: [...T], ignoreDependencies = true) {
    return ko.pureComputed(() => {
        const unwrappedDeps = <any>deps.map(v => ko.unwrap(v));

        return ignoreDependencies ?
            ko.ignoreDependencies(factory, null, unwrappedDeps) :
            factory(...unwrappedDeps);
    });
}

declare module 'knockout' {
    /**
     * Creates pure computed from limited number of dependencies
     * no inner subscriptions calls will trigger reevaluation
     * @param factory function which generates new value
     * @param deps dependencies array
    */
    export function strictComputed<T extends Array<any>, U>(factory: Func<U, UnwrappedTuple<[...T]>>, deps: [...T], ignoreDependencies?: boolean): PureComputed<U>
}