import { globalComponents } from 'extensions/knockout/components/global';
import { componentsLibrary } from 'models/components/library';
import { DefaultViewModel } from 'viewmodels/default';
import { TemplateConfig, TemplateInfo, ViewModelConfig, ViewModelFactory } from '../types/componentDefinition';

const defaultViewModelFactory = () => DefaultViewModel;
const awaiters: { [id: string]: Promise<object> } = {};
const activeAwaiters = ko.observableArray<Promise<object>>();

type GlobalComponentsNames = keyof typeof globalComponents;

export class ComponentsContext {
    components: Array<string> = []

    register(name: string, vm?: ViewModelFactory, template?: TemplateInfo, syncOnly = false) {
        name = name.toLowerCase();
        vm = vm ?? defaultViewModelFactory;
        template = template ?? 'wcc-components-' + name;

        let vmConfig = this.getViewModelConfig(vm),
            templateConfig = this.getTemplateConfig(name, template);

        this.components.push(name);

        if (componentsLibrary.isRegistered(name)) {
            componentsLibrary.register(name, vm);
        }
        else {
            this.registerInKO(name, vmConfig, templateConfig, syncOnly);
            componentsLibrary.register(name, vm);
        }

        return this;
    }

    registerLocal(name: string, vm?: ViewModelFactory, template?: TemplateInfo) {
        name = name.toLowerCase();

        return this.register(name, vm, template, true).preload(name as any);
    }

    preload(...nameOrNames: Array<GlobalComponentsNames>): ComponentsContext {
        nameOrNames.forEach(name => this.getAwaiter(name));
        return this;
    }

    async await(...names: Array<string>): Promise<void>
    async await(...names: Array<GlobalComponentsNames>): Promise<void>
    async await(...names: Array<string>) {
        const promises = names.map(name => this.getAwaiter(name));

        if (promises.length > 0)
            await Promise.all(promises);

        await this.awaitAll();
    }

    async awaitAll() {
        const promises = [...activeAwaiters()];

        if (promises.length > 0) {
            await Promise.all(promises);
            const currentPromises = [...activeAwaiters()];

            if (promises.length !== currentPromises.length)
                await this.awaitAll();
        }
    }

    dispose() {
        this.components.forEach(name => {
            if (componentsLibrary.hasOneOwner(name)) {
                ko.components.unregister(name);
                ko.components.unregister(name + '-sync');

                componentsLibrary.unregister(name);
            }
        });
    }

    private getAwaiter(name: string) {
        if (name.endsWith('-sync'))
            name = name.substr(0, name.length - 5);

        let awaiter = awaiters[name];

        if (awaiter == undefined) {
            const factory = componentsLibrary.getViewModel(name) as ViewModelFactory;

            if (factory) {
                awaiter = Promise.resolve(factory());
                awaiters[name] = awaiter;

                activeAwaiters.push(awaiter);

                awaiter.finally(() => activeAwaiters.remove(awaiter));
            } else {
                console.warn('component does not exist', name);
            }
        }

        return awaiter;
    }

    private getViewModelConfig(vm: ViewModelFactory): ViewModelConfig {
        if (!_(vm).isFunction())
            console.warn('components: you should provide a factory method');

        return { promise: vm };
    }

    private getTemplateConfig(name: string, template: TemplateInfo): TemplateConfig {
        if (typeof template === 'string') {
            if (template.length > 8 && template.substr(0, 8) === 'require:') {
                throw Error('unsupported template config');
            } else if (template === 'inline') {
                return '<!-- ko template: { nodes: $componentTemplateNodes } --><!-- /ko -->';
            } else {
                return { element: template };
            }
        } else if ('template' in template) {
            return template.template;
        } else if ('dynamicView' in template) {
            return { dynamicView: { name: template.dynamicView, elementId: 'wcc-components-' + name } }
        } else {
            return { element: template };
        }
    }

    private registerInKO(name: string, vmConfig: ViewModelConfig, templateConfig: TemplateConfig, syncOnly = false) {
        ko.components.register(name, {
            viewModel: vmConfig,
            template: templateConfig,
            synchronous: syncOnly
        });

        ko.components.register(name + '-sync', {
            viewModel: vmConfig,
            template: templateConfig,
            synchronous: true
        });
    };
}

export var instance = new ComponentsContext();