define('extensions/knockout/bindings/autogrow', [], () => {
    let init = function (element) {
        let $item = $(element);

        let $mirror = $('<div></div>'),
            mirror = $mirror[0];

        $item.after($mirror);

        // Style the mirror
        $mirror.css('display', 'none');
        $mirror.css('wordWrap', $item.css('wordWrap'));
        $mirror.css('whiteSpace', $item.css('whiteSpace'));
        $mirror.css('padding', $item.css('padding'));
        $mirror.css('width', $item.css('width'));
        $mirror.css('fontFamily', $item.css('font-family'));
        $mirror.css('fontSize', $item.css('font-size'));
        $mirror.css('lineHeight', $item.css('line-height'));

        // Style the textarea
        $item.css('overflow', "hidden");

        let onChange = (key) => {
            if (!_(key).isString() || key.length > 1)
                key = '';

            let value = $item.val()
                .replace(/&/g, '&amp;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#39;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/\n/g, '<br />') + key + '<br/>';

            $mirror.html(value);
            $mirror.css('width', $item.css('width'));

            $item.height($mirror.height());
        };

        let onKeyDown = e => onChange(e.key);

        $item.keydown(onKeyDown);
        $item.keyup(onChange);
        $item.on('changed', onChange);

        $item.on('autogrow-dispose', function () {
            $item.off('keydown', onKeyDown);
            $item.off('keyup', onChange);
            $item.off('changed', onChange);

            $mirror.remove();
        });

        onChange();
        _.defer(() => onChange());

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => $item.trigger('autogrow-dispose'));
    };

    let dispose = function (element) {
        $(element).trigger('autogrow-dispose');
    };

    ko.bindingHandlers.autogrow = {
        init: function (element, valueAccessor) {
            var val = ko.unwrap(valueAccessor());

            if (val) init(element);
        },

        update: function (element, valueAccessor) {
            var val = ko.unwrap(valueAccessor());

            dispose(element);

            if (val) init(element);
        }
    };
})