import ko, { MaybeSubscribable, SubscribableOrNullableValue } from 'knockout';

type Validator<T> = (item: T) => boolean

function defaultValidator(value: any) {
    return !(value == null || value == -Infinity || value === '');
}

ko.subscribable.fn.default = function <T, K>(defaultValue: SubscribableOrNullableValue<K>, validator: Validator<T> = defaultValidator) {
    const observable = this;

    function get(value: T) {
        if (defaultValue !== undefined && !validator(value))
            return ko.unwrap(defaultValue as MaybeSubscribable<K | undefined>);
        else
            return value;
    }

    return ko.pureComputed({
        read: () => get(observable()),
        write: value => observable(value)
    });
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Sets default value
         * @param defaultValue
         */
        default(defaultValue: SubscribableOrValue<NonNullable<T>>, validator?: (item: T) => boolean): PureComputed<NonNullable<T>>

        /**
         * Sets default value
         * @param defaultValue
         */
        default(defaultValue: SubscribableOrNullableValue<T>, validator?: (item: T) => boolean): PureComputed<NonNullable<T> | undefined>
    }
}