import { DOMHelpers } from 'helpers/dom';
import { Observable, SubscribableOrNullableValue } from 'knockout';
import NodeVisibilityTracker from 'managers/nodes/visibilityTracker';
import { withEffect } from 'mixins/withEffect';
import { withEffects } from 'mixins/withEffects';

export interface inViewPortNewBindingConfig {
    value: SubscribableOrNullableValue<boolean>
    element?: SubscribableOrNullableValue<HTMLElement>
    container?: SubscribableOrNullableValue<HTMLElement>
    enable?: SubscribableOrNullableValue<boolean>
    ignoreHeight?: SubscribableOrNullableValue<boolean>
}

ko.bindingHandlers.inViewPortNew = {
    init: function (element: Node, valueAccessor) {
        const data: Observable<boolean> | inViewPortNewBindingConfig = valueAccessor();
        const effects = withEffects();

        const oElement = ko.observable<Node>().unwrap().default(element);
        const oContainer = ko.observable<Node>().unwrap().default(DOMHelpers.getScrollingContainer(element) ?? document.body);
        const oEnable = ko.observable<boolean>().unwrap().default(true);
        const oIgnoreHeight = ko.observable<boolean>().unwrap().default(false);

        let oValue: Observable<boolean>;

        if (ko.isWritableObservable(data)) {
            oValue = data;
        } else {
            const config = (<inViewPortNewBindingConfig>data);

            if (ko.isWritableObservable<boolean>(config.value)) {
                oValue = config.value;
                oElement(<any>config.element);
                oContainer(<any>config.container);
                oEnable(<any>config.enable);
                oIgnoreHeight(<any>config.ignoreHeight);
            } else {
                throw {
                    error: 'you must provide writable observable',
                    binding: 'inViewPortNew',
                    element: element
                }
            }
        }

        effects.register(() => {
            const visibilityTracker = new NodeVisibilityTracker({ element: oElement, container: oContainer, enable: oEnable, ignoreHeight: oIgnoreHeight });

            return [
                withEffect(isVisible => oValue(isVisible), [visibilityTracker.isVisible]),
                () => visibilityTracker.dispose()
            ]
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());
    }
}