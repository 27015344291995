import * as ko from 'knockout';

ko.subscribable.fn.update = function (funcOrValue: any) {
    let newValue = _(funcOrValue).isFunction() ?
        funcOrValue(this()) :
        funcOrValue;

    if (newValue !== undefined)
        this(newValue);
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * updates observable only if new value is not undefined
         * @param func function to create new value from previous one
         */
        update(func: (value: T) => T): void

        /**
         * updates observable only if new value is not undefined
         * @param value new value
         */
        update(value: T | undefined): void
    }
}