import ko, { Observable } from 'knockout';

export interface IonRangeSliderModelConfig<T> {
    label?: string
    value?: number
    originalItem: T
}

export class IonRangeSliderModel<T = any> {
    isLabelVisible = ko.observable(false);

    label: Observable<string | undefined>
    value: Observable<number | undefined>
    originalItem: Observable<T>

    constructor(config: IonRangeSliderModelConfig<T>) {
        this.label = ko.observable(config.label);
        this.value = ko.observable(config.value);
        this.originalItem = ko.observable(config.originalItem);
    }
}

ko.bindingHandlers.rangeSlider = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const $element = $(element);
        const options = allBindings().rangeOptions ?? {};
        const sliderValues = options.values;

        var sliderOptions = <IonRangeSliderOptions>{
            type: options.toValue ? 'double' : 'single',
            step: options.step || 1,
            grid: options.grid === undefined ? true : options.grid,
            hide_min_max: options.hide_min_max || false,
            hide_from_to: options.hide_from_to || false
        }

        if (options.skin)
            (<any>sliderOptions).skin = options.skin;

        if (sliderValues && sliderValues.length) { //we may add support for two sliders for WCCRangleSliderModel
            if (options.usesRangleSliderModel) {
                sliderOptions.values = sliderValues.map((i: IonRangeSliderModel) => i.value());
                sliderOptions.prettify_enabled = true;

                sliderOptions.prettify = num => {
                    var obj = _.find(sliderValues, (e: IonRangeSliderModel) => e.value() == num);

                    if (obj == null || !obj.isLabelVisible())
                        return "";

                    return obj.label();
                }

                (<any>sliderOptions).current_label = (num: any) => {
                    var obj = _.find(sliderValues, (e: IonRangeSliderModel) => e.value() == num);
                    if (obj == null) {
                        return "";
                    }
                    return obj.label();
                }
            }
            else {
                sliderOptions.values = sliderValues;
            }
        }
        else {
            $.extend(sliderOptions, {
                min: options.min || 0,
                max: options.max || 20,
                from: options.from,
                from_min: options.from_min || options.min
            });

            if (options.toValue) {
                $.extend(sliderOptions, {
                    to: options.to,
                    to_max: options.to_max || options.max
                });
            }
        }

        sliderOptions.onFinish = obj => {
            var value = valueAccessor(),
                fromValToPush = null,
                toValToPush = null;

            if (options.usesRangleSliderModel) {
                var item = _.find(sliderValues, function (e) { return e.value() == obj.from_value; });

                if (item != null) {
                    fromValToPush = item.originalItem();
                }

                if (options.toValue) {
                    var toItem = _.find(sliderValues, function (e) { return e.value() == obj.to_value; });

                    if (toItem != null) {
                        toValToPush = toItem.originalItem();
                    }
                }
            }
            else {
                if (obj.from_value != null) {
                    fromValToPush = obj.from_value;
                } else if (obj.from != null) {
                    fromValToPush = obj.from;
                }

                if (options.toValue && obj.to_value != null) {
                    toValToPush = obj.to_value;
                }
            }

            if (fromValToPush != null) {
                if (Array.isArray(value())) {
                    value.removeAll();
                    value.push(fromValToPush);
                }
                else {
                    value(fromValToPush);
                }
            }
            if (toValToPush != null) {
                if (Array.isArray(options.toValue)) {
                    options.toValue.removeAll();
                    options.toValue.push(toValToPush);
                }
                else {
                    options.toValue(toValToPush);
                }
            }
        }

        $element.ionRangeSlider(sliderOptions);

        const slider = $element.data("ionRangeSlider");

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => slider.destroy());
    },

    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var eObj = $(element),
            slider = eObj.data("ionRangeSlider"),
            options = allBindings().rangeOptions || {},
            value = valueAccessor(),
            sliderValues = options.values;

        if (sliderValues && sliderValues.length) {
            var selectedItem: any = null;

            if (options.usesRangleSliderModel) {
                selectedItem = _(sliderValues).find(function (i) { return i.originalItem() == (Array.isArray(value()) && value().length > 0 ? value()[0] : value); });
            }
            else {
                selectedItem = _(sliderValues).find(function (i) { return i == (Array.isArray(value()) && value().length > 0 ? value()[0] : value); });
            }
            if (selectedItem) {
                _.defer(function () {
                    slider.update({
                        from: sliderValues.indexOf(selectedItem)
                    });
                });
            }
        }
        else {
            slider.update({
                from: ko.unwrap(value)
            });

            if (options.toValue) {
                slider.update({
                    to: options.toValue()
                });
            }
        }
    }
};