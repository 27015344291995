import ko from 'knockout';

ko.bindingHandlers.dom = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const value = valueAccessor();

        requestAnimationFrame(() => {
            if (ko.isObservable(value) || _.isFunction(value)) {
                value(element);

                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    if (value() == element)
                        value(undefined);
                });
            } else if (_.isArray(value)) {
                value.forEach(item => item(element));

                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    value.forEach(item => {
                        if (item() == element)
                            item(undefined);
                    });
                });
            }
        });
    }
}