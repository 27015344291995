import ko, { Observable } from "knockout";
import { getObservableAccesser } from "../../../helpers/knockout";
import { Func } from "../../../interfaces/func";
import { ObservableMapping } from "../../../models/observableMapping";

function mapSingle<T, K>(this: Observable<T>, predicateOrConfig: Func<K, [NonNullable<T>]> | ObservableMapping<NonNullable<T>, K>, context?: any, ignoreDependencies?: boolean) {
    const observable = this;
    const acceser = getObservableAccesser(this, predicateOrConfig);

    return ko.pureComputed({
        read: () => {
            return ignoreDependencies ?
                ko.ignoreDependencies(acceser.read, context, [observable()]) :
                acceser.read.call(context, observable());
        },

        write: function (value) {
            ko.ignoreDependencies(() => acceser.write.call(context, observable(), value));
        }
    });
}

(<any>ko.observable.fn).map = mapSingle;
ko.subscribable.fn.mapSingle = mapSingle;

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
        * returns computed which maps current value into another value using predicate
        * @param predicate
        * @param context
        * @param ignoreDependencies
        */
        mapSingle<K>(predicate: Func<K, [T]>, context?: any, ignoreDependencies?: boolean): PureComputed<K>

        /**
         * returns computed which maps current value into another value using config
         * supports write operation if write func is provided inside config
         * @param config
         * @param context
         * @param ignoreDependencies
         */
        mapSingle<K>(config: ObservableMapping<T, K>, context?: any, ignoreDependencies?: boolean): PureComputed<K>
    }
}