import { Func } from 'interfaces/func';
import ko, { UnwrappedTuple } from 'knockout';

ko.fromPromise = function <T extends Array<any>, U>(factory: Func<PromiseLike<U> | U, UnwrappedTuple<[...T]>> | Func<U>, deps: any[] = [], defaultValue?: U) {
    const awaiter = ko.strictComputed((...deps) => {
        const value = factory(...deps as any);

        if (_.isObject(value) && 'then' in value)
            return system.getPromiseAwaiter(value);
        else
            return value;
    }, deps);

    return ko.pureComputed(() => ko.unwrap(awaiter()) ?? defaultValue);
}

declare module 'knockout' {
    /**
     * Creates computed from promise
     * @param factory function which returns promise
    */
    export function fromPromise<U>(factory: Func<PromiseLike<U> | U>): PureComputed<U | undefined>

    /**
     * Creates computed from promise.
     * doesn't react to subscribables not specified in dependencies array
     * @param factory function which returns promise
     * @param deps dependencies array
    */
    export function fromPromise<T extends Array<any>, U>(factory: Func<PromiseLike<U> | U, UnwrappedTuple<[...T]>>, deps: [...T]): PureComputed<U | undefined>

    /**
     * Creates computed from promise.
     * doesn't react to subscribables not specified in dependencies array
     * allows to set default value
     * @param factory function which returns promise
     * @param deps dependencies array
     * @param defaultValue default value
    */
    export function fromPromise<T extends Array<any>, U>(factory: Func<PromiseLike<U> | U, UnwrappedTuple<[...T]>>, deps: [...T], defaultValue: NonNullable<U>): PureComputed<NonNullable<U>>
}