import ko from 'knockout';

ko.subscribable.fn.toggle = function () {
    this(!this());
}

declare module 'knockout' {
    export interface SubscribableFunctions {
        /** changes inner value from true to false and vice versa */
        toggle(): void
    }
}