import Bluebird from 'bluebird';
import { DOMHelpers } from 'helpers/dom';
import ko, { SubscribableOrNullableValue } from 'knockout';
import { withEffects } from 'mixins/withEffects';

interface Tooltip {
    show: Action
    hide: Action
    destroy: Action
    scrollTo: Action
}

export interface TooltipConfig {
    message?: SubscribableOrNullableValue<string>
    visible?: SubscribableOrNullableValue<boolean>
    placement?: SubscribableOrNullableValue<TooltipPlacement>
    force?: SubscribableOrNullableValue<boolean>
    delay?: SubscribableOrNullableValue<number>
    container?: SubscribableOrNullableValue<string | HTMLElement>
    scrollTo?: SubscribableOrNullableValue<boolean>
}

export type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left' | 'smart';

ko.bindingHandlers.tooltip = {
    init: function (element, valueAccessor) {
        const effects = withEffects();

        const getPlacement = (placement: TooltipPlacement) => {
            return placement === 'smart' ?
                (left() > windowWidth() / 2 ? 'left' : 'right') :
                placement;
        }

        const messageOrSettings = ko.flattenComputed<string | TooltipConfig>(valueAccessor());
        const message = messageOrSettings.pluck(value => _.isString(value) ? value : ko.unwrap(value.message), '').extend({ notify: 'always' });
        const settings = messageOrSettings.pluck(value => _.isString(value) ? <TooltipConfig>{} : value);
        const visible = settings.pluck(s => ko.unwrap(s.visible), true);
        const placement = settings.pluck(s => ko.unwrap(s.placement), 'top').mapSingle(getPlacement);
        const force = settings.pluck(s => ko.unwrap(s.force), false);
        const delay = settings.pluck(s => ko.unwrap(s.delay), 0);
        const container = settings.pluck(s => ko.unwrap(s.container));
        const scrollTo = settings.pluck(s => ko.unwrap(s.scrollTo), false);

        const $item = $(element);
        const $window = $(window);
        const left = ko.observable(0);
        const windowWidth = ko.observable(0);

        const isReady = ko.pureComputed(() => visible() && message().length > 0);

        const data = ko.pureComputed(() => {
            if (isReady())
                return <BootstrapTooltipOptions>{
                    title: message(),
                    delay: delay(),
                    placement: placement(),
                    container: container()
                };
        }).extend({ deferred: true });

        const tooltip = ko.observable<Tooltip>();

        const updatePosition = () => {
            left($item.offset()?.left ?? 0);
            windowWidth($window.width() ?? 0);
        }

        effects.register(async data => {
            if (data != undefined) {
                const newTooltip = <Tooltip>{
                    show: () => {
                        updatePosition();
                        $item.tooltip('show');
                    },
                    hide: () => $item.tooltip('hide'),
                    destroy: () => $item.tooltip('destroy'),

                    scrollTo: () => $('html, body').animate({ scrollTop: $item.offset()?.top ?? 0 - 100 })
                }

                await Bluebird.delay(150);
                
                $item.tooltip(data);
                tooltip(newTooltip);

                return () => {
                    newTooltip.hide();
                    newTooltip.destroy();

                    tooltip(undefined);
                }
            }
        }, [data]);

        effects.register((tooltip, force) => {
            if (tooltip != undefined && force)
                tooltip.show();
        }, [tooltip, force]);

        effects.register((tooltip, scrollTo) => {
            if (tooltip != undefined && scrollTo)
                tooltip.scrollTo();
        }, [tooltip, scrollTo]);

        effects.register(tooltip => {
            if (tooltip != undefined) {
                return [
                    DOMHelpers.onNodeEvent(element, 'mouseenter', () => tooltip.show()),

                    () => tooltip.destroy()
                ];
            }
        }, [tooltip]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());
    }
}