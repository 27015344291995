import ko from 'knockout';

ko.subscribable.fn.inc = function (n = 1) {
    const observable = this;
    const value = observable();

    if (_.isNumber(value))
        observable(value + n);
    else
        throw 'value is not a number';
}

declare module 'knockout' {
    export interface SubscribableFunctions {
        /**
         * increments inner value by specified amount
         * @param amount
         */
        inc(amount?: number): void
    }
}