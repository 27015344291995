import { Func } from 'interfaces/func';
import ko, { Observable, Subscribable } from 'knockout';

ko.subscribable.fn.proxy = function <T, K>(this: Subscribable<T>, extractor: Func<Observable<K>, [NonNullable<T>]>) {
    const buffer = ko.observable<K>();

    return this.mapSingle({
        read: item => {
            if (item != undefined) {
                if (buffer() != undefined) {
                    extractor(<NonNullable<T>>item)(<K>buffer());
                    buffer(undefined);
                }

                return extractor(<NonNullable<T>>item)();
            } else {
                return buffer();
            }
        },

        write: (item, value) => {
            if (item != undefined)
                extractor(<NonNullable<T>>item)(value);
            else
                buffer(value);
        }
    })
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        proxy<K>(extractor: Func<Observable<K>, [NonNullable<T>]>): PureComputed<K | undefined>
    }
}