import ko from 'knockout';

ko.subscribable.fn.dec = function (n = 1) {
    const observable = this;
    const value = observable();

    if (_.isNumber(value))
        observable(value - n);
    else
        throw 'value is not a number';
}

declare module 'knockout' {
    export interface SubscribableFunctions {
        /**
         * decrements inner value by specified amount
         * @param amount
         */
        dec(amount?: number): void
    }
}