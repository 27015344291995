import { Func } from 'interfaces/func';
import ko, { Subscribable, SubscribableOrValue } from 'knockout';

ko.subscribable.fn.is = function <T>(this: Subscribable<T>, ...values: Array<SubscribableOrValue<T> | Func<boolean, [T]>>): Subscribable<boolean> {
    const observable = this;

    if (values.length > 1) {
        return ko.pureComputed(() => values.some(value => observable() == ko.unwrap(<SubscribableOrValue<T>>value)));
    } else if (values.length == 1) {
        const predicateOrValue = values[0];

        if (ko.isSubscribable(predicateOrValue))
            return ko.pureComputed(() => observable() === predicateOrValue());
        else if (_.isFunction(predicateOrValue))
            return ko.pureComputed(() => <boolean>predicateOrValue(observable()));
        else
            return ko.pureComputed(() => observable() === predicateOrValue);
    } else {
        return ko.pureComputed(() => false);
    }    
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Checks if inner value matches any provided value
         * @param values
         */
        is(...values: Array<SubscribableOrValue<T>>): Subscribable<boolean>

        /**
         * Checks if inner value matches provided predicate
         * @param value
         */
        is(predicate: Func<boolean, [T]>): Subscribable<boolean>
    }
}