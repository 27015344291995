import * as ko from 'knockout';

ko.subscribable.fn.invokeNotNull = function (...args: Array<any>) {
    var observable = this,
        value = observable();

    if (value != undefined)
        return observable.invoke.apply(observable, args as any);

    return undefined;
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * invokes functions against inner value if inner value is not null
         * @param func function to call
         * @param context function context to be executed in(this)
         * @param ignoreDependencies calls function inside ignoreDependencies wrapper if true
         */
        invokeNotNull<K>(func: (value: NonNullable<T>) => K, context?: any, ignoreDependencies?: boolean): K | undefined

        /**
         * invokes inner object method with specified name if inner object is not null
         * @param funcToCall function to call
         * @param args arguments to pass to function
         */
        invokeNotNull(funcToCall: string, args: any[]): any
    }
}