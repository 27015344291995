define('extensions/knockout/functions/prev', [
    'knockout'
], function (ko) {
    /**
     * creates computed which returns previous subscribable value
     */
    ko.subscribable.fn.prev = function () {
        var observable = this,
            value = ko.observable(null),
            result = value.readOnly();

        var sub = observable.subscribe(v => value(v), null, 'beforeChange');

        var _dispose = result.dispose.bind(result);

        result.dispose = function () {
            _dispose();
            sub.dispose();
        };

        return result;
    };
});