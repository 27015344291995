import { componentTemplateMetadataKey } from 'decorators/componentTemplate';
import TemplateLoader from 'extensions/knockout/loaders/promise/template';
import ViewModelLoader from 'extensions/knockout/loaders/promise/vm';
import { TemplateConfig, ViewModelConfig } from 'extensions/knockout/types/componentDefinition';
import { isInjectable } from 'interfaces/injectable';
import { componentSectionsMetadataKey } from '../../../../decorators/componentSection';
import WCCComponentMasterLoader from './master';

const sectionPattern = /<!-- ko section: \$(\w+) --><!-- \/ko -->/gi;

export default class WCCComponentLoader {
    private viewModelLoader = new ViewModelLoader();
    private templateLoader = new TemplateLoader();
    private masterLoader = new WCCComponentMasterLoader();

    loadComponent(name: string, config: ko.components.Config, callback: (component: ko.components.Component) => void) {
        if (config) {
            this.viewModelLoader.getViewModelConstructor(config.viewModel).then(async ctor => {
                await this.processDynamicViews(name, config);
                this.updateConfigFromViewModelConstructor(ctor, config);

                this.viewModelLoader.load(name, config.viewModel, createViewModel =>
                    this.templateLoader.load(name, config.template, template =>
                        callback({ createViewModel, template })));
            });
        } else {
            ko.components.defaultLoader.loadComponent(name, config, callback);
        }
    }

    loadViewModel(name: string, config: ko.components.ViewModelConfig | ViewModelConfig, callback: (vm: ko.components.CreateViewModel) => void) {
        this.viewModelLoader.load(name, config, callback);
    }

    loadTemplate(name: string, config: ko.components.TemplateConfig | TemplateConfig, callback: (template: Node[]) => void) {
        this.templateLoader.load(name, config, callback);
    }

    private updateConfigFromViewModelConstructor(ctor: any, config: ko.components.Config) {
        if (isInjectable(ctor)) {
            const templateId: string | undefined = Reflect.getMetadata(componentTemplateMetadataKey, ctor);

            if (templateId != undefined)
                config.template = { element: templateId };

            if ('element' in config.template) {
                const master = this.masterLoader.load(ctor);
                const sections: Map<string, string> = Reflect.getMetadata(componentSectionsMetadataKey, ctor) ?? new Map<string, Array<string>>();

                const templateId: string = config.template.element;

                if (master != undefined || sections.size > 0) {
                    let template = document.getElementById(templateId)?.innerHTML ?? '';

                    if (master != undefined)
                        template = master.replace('<!-- ko template: $masterBody --><!-- /ko -->', template);

                    template = template.replace(sectionPattern, (match, sectionName) => {
                        const sectionId = sections.get(sectionName);

                        if (sectionId != undefined)
                            return `<!-- ko template: '${sectionId}'--><!-- /ko -->`

                        return '';
                    });

                    config.template = template;
                }
            }
        }
    }

    private async processDynamicViews(name: string, config: ko.components.Config) {
        if (_.isObject(config.template) && 'dynamicView' in config.template) {
            //template loader will load dynamic view and we can just use templateId from now on
            await new Promise<void>(resolve => this.templateLoader.load(name, config.template, () => resolve()));

            const { elementId } = config.template.dynamicView;
            config.template = { element: elementId };
        }
    }
}