import { Func } from 'interfaces/func';
import ko, { SimpleValue, Subscribable } from 'knockout';
import { CollectionsHelpers } from '../../../helpers/collections';

ko.subscribable.fn.map2 = function <T, K>(this: Subscribable<T>, key: Func<boolean, [SimpleValue<T>, K]> | string, factory: Func<K, [SimpleValue<T>, number]>, updateFunc?: Action<[K, SimpleValue<T>]>) {
    const items = this;
    let cache: Array<K> = [];

    return items.mapSingle(items => {
        if (_.isArray(items)) {
            const predicate = _.isFunction(key) ?
                (left: SimpleValue<T>, right: K) => key(left, right) :
                (left: SimpleValue<T>, right: K) => ko.unwrap((<any>left)[key]) === ko.unwrap((<any>right)[key]);

            const state = items.map((data: SimpleValue<T>, idx) => {
                const mappedItem = cache.find(mappedItem => predicate(data, mappedItem));

                if (mappedItem != undefined) {
                    if (updateFunc != undefined)
                        updateFunc(mappedItem, data);

                    return mappedItem;
                } else {
                    return factory(data, idx);
                }
            });

            if (!CollectionsHelpers.compareCollections(cache, state))
                cache = state;

            return cache;
        }

        return [];
    }).extend({ notifyIfChanged: true });
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * returns computed which maps collection into another collection using predicate
         * on change we look for mapped item in cache using {key} and update it with {updateFunc}. If item is not found then we map it using {factory}
         * @param key
         * @param factory
         * @param updateFunc
         */
        map2<K>(key: Func<boolean, [SimpleValue<T>, K]> | string, factory: Func<K, [SimpleValue<T>, number]>, updateFunc?: Action<[mappedItem: K, data: SimpleValue<T>]>): Subscribable<Array<K>>
    }
}