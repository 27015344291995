import ko, { ResetableComputed, Subscribable } from 'knockout';

ko.subscribable.fn.toFlag = function <T>(this: Subscribable<T>, delay?: number) {
    const observable = this;
    const value = ko.observable(false);

    function reset() {
        value(false);
    }

    const result = observable.mapSingle(currentValue => {
        if (currentValue)
            value(true);

        return value();
    }) as any as ResetableComputed<T>;

    if (_.isNumber(delay))
        result.extend({ rateLimit: delay });

    result.reset = reset;

    return result;
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {

        /**
         * Creates computed which always returns true once source subscribable becomes true
         * In other words it creates one time gate which can be closed once opened. usually used for lazy rendering
         * @param delay
         */
        toFlag(delay?: number): ResetableComputed<boolean>
    }
}