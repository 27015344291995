import ko, { Observable, Subscribable } from 'knockout';

export interface ValidatableSubscribable<T> extends Subscribable<T> {
    error: Subscribable<string | undefined>
    hasError: Subscribable<boolean>
    isValid: Subscribable<boolean>
    scrollTo: Observable<boolean>
}

export function IsValidatableSubscribable<T = any>(value: any): value is ValidatableSubscribable<T> {
    return ko.isSubscribable(value) && ko.isSubscribable((<any>value).error);
}

ko.extenders.validate = function <T>(target: Subscribable<T>, predicate: (value: T) => string | undefined) {
    const error = target.mapSingle(value => predicate(value));
    const hasError = error.mapSingle(err => _.isString(err) && err.length > 0);
    const isValid = hasError.not();
    const scrollTo = ko.observable(false);

    return <ValidatableSubscribable<T>>_(target).extend({ error, hasError, isValid, scrollTo });
}

declare module 'knockout' {
    export interface ExtendersOptions<T> {
        validate: (value: T) => string | undefined
    }
}