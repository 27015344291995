define('extensions/knockout/functions/intersection', [
    'knockout'
], function (ko) {
    /**
     * calls function with observable value as argument
     */
    ko.subscribable.fn.intersection = function (other) {
        var observable = this;

        return ko.pureComputed(function () {
            return _(observable()).intersection(ko.unwrap(other));
        });
    };
});