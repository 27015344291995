import * as ko from 'knockout';

ko.subscribable.fn.whenNotNull = function<T> (this: ko.Subscribable<T>, disposalPromise?: Promise<boolean>) {
    return this.when(value => value != null, disposalPromise);
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * creates promise which is resolve when inner value is not null and is not undefined
         * @param disposalPromise if that promise is resolved then we stop tracking subscribable value
         */
        whenNotNull(disposalPromise?: Promise<boolean>): Promise<NonNullable<T>>
    }
}