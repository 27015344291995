import ko from 'knockout';

ko.subscribable.fn.last = function(n?: number): any {
    const observable = this;

    return n == undefined ?
        ko.pureComputed(() => _(observable()).last()) :
        ko.pureComputed(() => _(observable()).last(n));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed with last element in collection
         */
        last(): T extends Array<infer I> ? Subscribable<I | undefined> : never

        /**
         * Returns computed with last n elements in collection
         */
        last(n: number): T extends Array<infer I> ? Subscribable<Array<I>> : never
    }
}