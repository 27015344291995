import { TemplateConfig } from 'extensions/knockout/types/componentDefinition';
import { loadView } from 'helpers/views';

export default class WCCTemplateLoader {
    async load(name: string, config: ko.components.TemplateConfig | TemplateConfig, callback: (template: Node[]) => void) {
        try {
            if (_.isObject(config) && 'dynamicView' in config) {
                const { name: viewName, elementId } = config.dynamicView;
                await loadView(viewName);

                ko.components.defaultLoader.loadTemplate(name, { element: elementId }, callback);
            } else {
                ko.components.defaultLoader.loadTemplate(name, config, callback);
            }
        } catch (ex) {
            console.error(name, config, ex);
        }
    }
}