import ko, { SimpleValue } from 'knockout';

ko.subscribable.fn.sort = function <T>(compareFn?: (a: SimpleValue<T>, b: SimpleValue<T>) => number) {
    const observable = this;

    return ko.pureComputed(() => {
        const value = observable();

        if (_.isArray(value))
            return value.sort(compareFn);

        return [];
    });
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * sorts inner array using compare func
         * @param compareFn
         */
        sort(compareFn?: (a: SimpleValue<T>, b: SimpleValue<T>) => number): Subscribable<Array<SimpleValue<T>>>
    }
}