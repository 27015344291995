define('extensions/knockout/bindings/transitionComplete', [], function () {
    const domService = require('helpers/dom').DOMHelpers;

    /**
    * Executes function on transition completed event
    * {value} should be function.
    */
    ko.bindingHandlers.transitionComplete = {
        init: function (element, valueAccessor, allBindingsAccessor) {
            var $element = $(element),
                callback = valueAccessor(),
                chilrenTransitions = allBindingsAccessor()['transition-children'],
                onlyTargetEvents = chilrenTransitions == null || chilrenTransitions == false;

            var subs = domService.onTransitionComplete(element, function (e) {
                if (!onlyTargetEvents || e.target == element) {
                    callback();
                }
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                if (subs) subs.dispose();
            });
        }
    };
});