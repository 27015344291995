import { DOMHelpers } from 'helpers/dom';
import ko, { SubscribableOrNullableValue } from 'knockout';
import device from 'managers/device';
import { withEffects } from 'mixins/withEffects';

const windowOffset = 50;
const mobileWindowOffset = 10;

const maxWidthRestriction = 300;

function getElement(root: HTMLElement, element?: string | HTMLElement): HTMLElement | undefined {
    if (_.isString(element))
        return <HTMLElement | undefined>root.closest(element);
    else
        return element;
}

export interface SmartDropdownOptions {
    container?: SubscribableOrNullableValue<string | HTMLElement>
}

ko.bindingHandlers.smartDropdown = {
    init: function (element: HTMLElement, valueAccessor, allBindings) {
        const optionsOrValue: SmartDropdownOptions | boolean = valueAccessor();
        const options: SmartDropdownOptions | undefined = _.isBoolean(optionsOrValue) ? undefined : optionsOrValue;

        const $element = $(element);
        const container = ko.pureComputed(() => getElement(element, ko.unwrap(options?.container)) ?? <HTMLElement>DOMHelpers.getScrollingContainer(element));
        const effects = withEffects();

        function toggleClass($element: JQuery, className: string, enable: boolean) {
            if (enable) {
                if (!$element.hasClass(className))
                    $element.addClass(className);
            } else {
                if ($element.hasClass(className))
                    $element.removeClass(className);
            }
        }

        function update(container: HTMLElement) {
            const offset = device.isMobile() ? mobileWindowOffset : windowOffset;

            const $dropdown = $element.children('ul').first();
            const dropdownWidth = $dropdown.width() ?? 0;

            const $subMenu = $dropdown.find('.dropdown-submenu .dropdown-menu');
            const subMenuWidth = $subMenu.width() ?? 0;

            const elementRect = element.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            const isDropdown = elementRect.top - containerRect.top < container.clientHeight / 2;
            const shouldPullRightDropdown = elementRect.left + dropdownWidth + offset > containerRect.right && elementRect.right - dropdownWidth > containerRect.left;
            const isSubmenuOverflowed = elementRect.left + subMenuWidth > containerRect.right;

            const calcDropdownMaxWidth = shouldPullRightDropdown ?
                elementRect.right - offset :
                containerRect.right - elementRect.left - offset;

            const dropdownMaxHeight = isDropdown ?
                containerRect.bottom - elementRect.bottom - offset :
                elementRect.top - containerRect.top - offset;

            toggleClass($element, 'dropdown', isDropdown);
            toggleClass($element, 'dropup', !isDropdown);
            toggleClass($dropdown, 'up', !isDropdown);

            if (!$dropdown.hasClass('wcc-pull-right-fixed')) {
                toggleClass($dropdown, 'pull-right', shouldPullRightDropdown);
            }

            if (allBindings().doNotRestrictWidth != true) {
                const dropdownMaxWidth = Math.min(calcDropdownMaxWidth, maxWidthRestriction);
                $dropdown.css({ 'max-width': dropdownMaxWidth });
            }

            if (allBindings().doNotRestrictHeight != true) {
                if ($subMenu.length == 0)
                    $dropdown.css({ 'max-height': dropdownMaxHeight, 'overflow': 'auto' });
            }

            $subMenu.css({ 'left': isSubmenuOverflowed ? '-50%' : '' });
        }

        effects.scope((container, effects) => {
            if (document.documentElement.contains(element) && container != undefined) {
                const updateLazy = system.toUICallback(() => update(container));

                effects.register([
                    DOMHelpers.onNodeEvent(element, 'click', updateLazy),
                    DOMHelpers.onNodeEvent(element, 'hover', updateLazy),

                    device.onResize(updateLazy)
                ]);

                updateLazy();
            }
        }, [container]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());        
    }
}