import ko from 'knockout';

ko.subscribable.fn.all = function (...args: any[]) {
    return ko.pureComputed(() => _(this()).all(...args));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed which indicates if all inner array items match provided predicate
         * @param predicate
         */
        all(predicate?: (item: SimpleValue<T>) => boolean): Subscribable<boolean>
    }
}