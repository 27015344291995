define('extensions/knockout/functions/select', [
    'knockout'
], function (ko) {
    /**
     * projects observable value using func
     */
    ko.subscribable.fn.select = function (func) {
        var observable = this;

        func = func || _.identity;

        return func(observable());
    };
});