import ko from 'knockout';

ko.subscribable.fn.indexOf = function<T>(item: T) {
    const observable = this;

    return ko.pureComputed(() => _(observable()).indexOf(item));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns index of element in inner collection
         * @param predicate
         */
        indexOf(item: SimpleValue<T>): Subscribable<number>
    }
}