import ko, { SimpleValue, Subscribable } from 'knockout';

ko.subscribable.fn.includes = function <T>(this: Subscribable<T>, item: SimpleValue<T>) {
    return this.is(value => {
        if (_.isArray(value))
            return value.includes(item);

        return false;
    });
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed which indicates if inner array includes specified item
         * @param item item to check
         */
        includes(item: SimpleValue<T>): Subscribable<boolean>
    }
}