import _ from 'underscore/modules/index-default';

_.mixin({
    generate: function () {
        let startIdx, endIdx, step, mapper;

        if (arguments.length == 2) {
            startIdx = 0;
            endIdx = arguments[0];
            step = 1;
            mapper = arguments[1];
        } else if (arguments.length == 3) {
            startIdx = arguments[0];
            endIdx = arguments[1];
            step = 1;
            mapper = arguments[2];
        } else if (arguments.length == 4) {
            startIdx = arguments[0];
            endIdx = arguments[1];
            step = arguments[2];
            mapper = arguments[3];
        } else {
            throw 'invalid arguments exception';
        }

        return _(_.range(startIdx, endIdx, step)).map(mapper);
    }
});