import { Func } from 'interfaces/func';
import ko, { Observable, Subscribable, SubscribableOrValue } from 'knockout';

ko.subscribable.fn.chainNotNull = function <T, K>(this: Observable<T>, mapper: Func<SubscribableOrValue<K>, [value: Subscribable<NonNullable<T>>]>) {
    const observable = this;
    const hasValue = observable.isNotNull();

    return hasValue.pluck(hasValue => {
        if (hasValue)
            return mapper(<any>observable);
    });
};

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * allows to work with nullable subscribable without worrying of null/undefined
         * internally we will have 2 streams: undefined and mapped subscribable
         * used in algorithms where we need undefined to indicate some internal processes are not finished yet like data loading
         * @param mapper function which applies all transformations we need on non-nullable version of original subscribable
         */
        chainNotNull<K>(mapper: Func<SubscribableOrValue<K>, [value: Subscribable<NonNullable<T>>]>): Subscribable<K | undefined>
    }
}