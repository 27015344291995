import ko from 'knockout';

ko.observableMap = function <K, V>(source?: Map<K, V>) {
    const observable = ko.observable(source ?? new Map<K, V>());

    const has = (key: K): boolean => {
        return observable().has(key);
    }

    const get = (key: K): V | undefined => {
        return observable().get(key);
    }

    const set = (key: K, value: V): void => {
        observable.valueWillMutate();
        observable().set(key, value);
        observable.valueHasMutated();
    }

    const remove = (key: K): boolean => {
        return observable().delete(key);
    }

    const clear = () => {
        observable().clear();
    }

    const keys = () => ko.pureComputed(() => [...observable().keys()]);
    const values = () => ko.pureComputed(() => [...observable().values()]);
    const entries = () => ko.pureComputed(() => [...observable().entries()]);

    return <ko.ObservableMap<K, V>>_(observable).extend({ has, get, set, keys, values, entries, delete: remove, clear });
}

declare module 'knockout' {
    export interface ObservableMap<K, V> extends Observable<Map<K, V>> {
        keys(): Subscribable<K[]>
        values(): Subscribable<V[]>
        entries(): Subscribable<[K,V][]>

        has(key: K): boolean
        get(key: K): V | undefined
        set(key: K, value: V): void
        delete(key: K): boolean
        clear(): void
    }

    /**
     * Creates computed from subscribable or raw value.
     * @param value
    */
    export function observableMap<K, V>(source?: Map<K, V>): ObservableMap<K, V>
}