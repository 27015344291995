define('extensions/knockout/functions/tap', ['knockout'], function (ko) {
    ko.subscribable.fn.tap = function (action) {
        var observable = this;

        return ko.pureComputed({
            read: function () {
                var value = observable();
                action(value);

                return value;
            },
            write: function (value) {
                observable(value);
            }
        });
    };
});