import ko, { Observable, PureComputed } from 'knockout';

const setIfDifferent = <T>(observable: Observable<T> | PureComputed<T>, value: T) => {
    if (observable() !== value)
        observable(value);
}

ko.observable.fn.setIfDifferent = function <T>(this: Observable<T>, value: T) {
    setIfDifferent(this, value);
}

ko.computed.fn.setIfDifferent = function <T>(this: PureComputed<T>, value: T) {
    setIfDifferent(this, value);
}

declare module 'knockout' {
    export interface ObservableFunctions<T> {
        /**
         * Updates inner observable value only if it is different from provided value
         * Allows to avoid unnecessary notifications 
         * @param value
         */
        setIfDifferent(value: T): void
    }

    export interface ComputedFunctions<T> {
        /**
         * Updates inner observable value only if it is different from provided value
         * Allows to avoid unnecessary notifications 
         * @param value
         */
        setIfDifferent(value: T): void
    }
}