import { Observable } from 'knockout';
import { withEffects } from 'mixins/withEffects';

ko.bindingHandlers.iCheckNew = {
    init: (element, valueAccessor, allBindings) => {
        const effects = withEffects();

        const $element = $(element);
        const value = <Observable<boolean>>valueAccessor();

        const mutations = ko.observable(0);

        var options = <ICheckOptions>{
            checkboxClass: 'icheckbox_' + (allBindings().iClass || 'flat-blue'),
            radioClass: 'iradio_' + (allBindings().iClass || 'flat-blue')
        }

        const setValue = (newValue: boolean) => {
            value(newValue);
            mutations.inc();
        }

        $element.iCheck(options)
            .on('ifChecked', () => setValue(true))
            .on('ifUnchecked', () => setValue(false));

        const $iCheck = $element.parent();
        const isChecked = () => $iCheck.hasClass('checked');

        effects.register(value => {
            if (value != isChecked()) {
                if (value)
                    $element.iCheck('check');
                else
                    $element.iCheck('uncheck');
            }

            //force sync with html(safari bug fix)
            if (value != isChecked()) {
                if (value)
                    $iCheck.addClass('checked');
                else
                    $iCheck.removeClass('checked');
            }
        }, [value, mutations]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());
    }
}