import ko, { SubscribableOrNullableValue } from 'knockout';
import { Func } from '../../../interfaces/func';

ko.flattenComputed = function <T, K>(value: SubscribableOrNullableValue<T> | Func<SubscribableOrNullableValue<T>>, defaultValue: SubscribableOrNullableValue<K>) {
    if (!ko.isSubscribable(value) && _.isFunction(value)) {
        const wrapper = ko.pureComputed(() => value());
        return ko.pureComputed(() => ko.unwrap(wrapper()) ?? ko.unwrap(defaultValue));
    } else {
        return ko.pureComputed(() => ko.unwrap(value) ?? ko.unwrap(defaultValue));
    }
} as any

declare module 'knockout' {
    /**
     * Creates computed from subscribable or raw value.
     * @param value
     */
    export function flattenComputed<T>(value: SubscribableOrValue<T>): Subscribable<T>

    /**
     * Creates computed from subscribable or raw value.
     * @param value
     */
    export function flattenComputed<T>(value: Func<SubscribableOrValue<T>>): Subscribable<T>

    /**
     * Creates computed from subscribable or nullable value
     * @param value
     */
    export function flattenComputed<T>(value: SubscribableOrNullableValue<T>): Subscribable<T | undefined>

    /**
     * Creates computed from subscribable or nullable value
     * @param value
     */
    export function flattenComputed<T>(value: Func<SubscribableOrNullableValue<T>>): Subscribable<T | undefined>

    /**
     * Creates computed from subscribable or nullable value. allows to set default value
     * @param value
     * @param defaultValue
     */
    export function flattenComputed<T>(value: SubscribableOrNullableValue<T>, defaultValue: SubscribableOrValue<NonNullable<T>>): Subscribable<NonNullable<T>>

    /**
     * Creates computed from subscribable or nullable value. allows to set default value
     * @param value
     * @param defaultValue
     */
    export function flattenComputed<T>(value: Func<SubscribableOrNullableValue<T>>, defaultValue: SubscribableOrValue<NonNullable<T>>): Subscribable<NonNullable<T>>

    /**
     * Creates computed from subscribable or nullable value. allows to set nullable default value
     * @param value
     * @param defaultValue
     */
    export function flattenComputed<T>(value: SubscribableOrNullableValue<T>, defaultValue: SubscribableOrNullableValue<T>): Subscribable<NonNullable<T> | undefined>

    /**
     * Creates computed from subscribable or nullable value. allows to set nullable default value
     * @param value
     * @param defaultValue
     */
    export function flattenComputed<T>(value: Func<SubscribableOrNullableValue<T>>, defaultValue: SubscribableOrNullableValue<T>): Subscribable<NonNullable<T> | undefined>
}