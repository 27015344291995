define('extensions/knockout/functions/toNodes', [
    'knockout'
], function (ko) {
    /**
     * creates computed which returns previous subscribable value
     */
    ko.subscribable.fn.toNodes = function () {
        let value = this();

        if (_(value).isString())
            return _($(value)).toArray();

        return [];
    };
});