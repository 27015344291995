import { DOMHelpers } from 'helpers/dom';
import { withEffects } from 'mixins/withEffects';

/**
* Executes function on animation completed event
* {value} should be function.
*/
ko.bindingHandlers.animationComplete = {
    init: function (element, valueAccessor) {
        const action = valueAccessor();
        const effects = withEffects();

        if (_.isFunction(action))
            effects.register(DOMHelpers.onAnimationComplete(element, action));

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());
    }
};