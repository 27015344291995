import ko from 'knockout';

ko.subscribable.fn.whenFalse = function <T>(this: ko.Subscribable<T>, disposalPromise?: Promise<boolean>) {
    return this.when(value => !value, disposalPromise);
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * creates promise which is resolve when inner value is false
         * @param disposalPromise if that promise is resolved then we stop tracking subscribable value
         */
        whenFalse(disposalPromise?: Promise<boolean>): Promise<NonNullable<T>>
    }
}