import ko, { SimpleValue } from 'knockout';

ko.subscribable.fn.find = function <T>(predicate?: (item: NonNullable<SimpleValue<T>>) => boolean, ...args: Array<any>) {
    const observable = this;

    return <any>ko.pureComputed(() => _(observable()).find(predicate, ...args));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns first item matching predicated as computed
         * @param predicate
         */
        find<K extends SimpleValue<T> = SimpleValue<T>>(predicate?: (item: NonNullable<SimpleValue<T>>) => boolean): Subscribable<K | undefined>
    }
}