import { globalComponents } from './components/global';
import PromiseLoader from './loaders/promise/loader';

system.importAll(require.context('./bindings/', false, /\.[jt]s$/));

ko.components.loaders.unshift(new PromiseLoader());

_(globalComponents).each((definition, name) =>
    components.register(name, definition.vm, definition.template));

components.preload('spinner-new', 'wcc-loader', 'wcs-spinner', 'component-awaiter');