import { ViewModelConfig } from 'extensions/knockout/types/componentDefinition';

export default class WCCViewModelLoader {
    async load(name: string, config: ko.components.ViewModelConfig | ViewModelConfig, callback: (vm: ko.components.CreateViewModel) => void) {
        if (config && 'promise' in config) {
            try {
                const ViewModel = await this.getViewModelConstructor(config);
                await components.awaitAll();

                try {
                    if (ViewModel == undefined)
                        throw new Error(`components: invalid ViewModel for component: ${name}`);

                    const { ViewModelFactory } = await import('extensions/knockout/factories/vm');
                    const factory = new ViewModelFactory(ViewModel);

                    callback((params, componentInfo) => factory.createViewModel(params, componentInfo));                  
                } catch (ex) {
                    console.error(ex);
                }
            } catch (ex) {
                console.error('components: invalidconfig', config);
                throw ex;
            }
        } else {
            ko.components.defaultLoader.loadViewModel(name, config, callback);
        }
    }

    async getViewModelConstructor(config: ko.components.ViewModelConfig | ViewModelConfig) {
        if (config && 'promise' in config) {
            const value = await Promise.resolve(config.promise());
            return system.getDefaultIfES6(value);
        }
    }
}