define('extensions/knockout/functions/mergeWith', [
    'knockout'
], function (ko) {
    /**
     * Merge 2 values into array
     * @param {observable} other observable to merge with
     * @param {bool} allowNull if null values are not allowed and any value is null then result will become null otherwise array with null items will be returned
     * @returns {computed} new pure computed
     */
    ko.subscribable.fn.mergeWith = function (other, allowNull) {
        var observable = this;

        return ko.pureComputed(function () {
            var left = observable(),
                right = other();

            if (!allowNull && (left == null || right == null))
                return null;

            return (_(left).isArray() ? left : [left]).concat(
                _(right).isArray() ? right : [right]);
        });
    };
});