import ko, { Subscribable } from 'knockout';

export interface OnArrayChangeConfig<T> {
    addRange?: Action<[items: Array<T>]>
    removeRange?: Action<[items: Array<T>]>

    add?: Action<[item: T, idx: number]>
    remove?: Action<[item: T, idx: number]>
    move?: Action<[item: T, to: number, from: number]>
}

ko.subscribable.fn.onArrayChange = function <T>(this: Subscribable<Array<T>>, config: OnArrayChangeConfig<T>) {
    return this.subscribe(changes => {
        let offset = 0;

        const added = new Array<T>();
        const removed = new Array<T>();

        changes.forEach(change => {
            var idx = change.index + offset;

            if (change.status === 'added') {
                if (change.moved !== undefined) {
                    config.move?.(change.value, change.index, <number>change.moved);
                } else {
                    if (config.addRange != undefined)
                        added.push(change.value);

                    config.add?.(change.value, idx);
                    offset++;
                }
            } else if (change.status === 'deleted' && change.moved === undefined) {
                if (config.removeRange != undefined)
                    removed.push(change.value);

                config.remove?.(change.value, idx);
                offset--;
            }
        });

        if (config.addRange != undefined && added.length > 0)
            config.addRange(added);

        if (config.removeRange != undefined && removed.length > 0)
            config.removeRange(removed);
    }, null, 'arrayChange');
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Subscribes to array changes. subscribable should have array tracking enabled
         * @param config add, remove, update callbacks to call on array change
         */
        onArrayChange(config: OnArrayChangeConfig<SimpleValue<T>>): Subscription
    }
}