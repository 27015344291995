import ko from 'knockout';

ko.subscribable.fn.subscribeIf = function (args: Array<any>, predicate: (value: any) => boolean = _.identity) {
    let [func, target, event] = _(args).isArray() ? args : [args];

    return this.subscribe((value: any) => {
        if (predicate(value) == true)
            func.call(target, value);
    }, target, event);
}

ko.subscribable.fn.subscribeNotNull = function(...args: Array<any>) {
    return ko.subscribable.fn.subscribeIf.call(this, args as any, value => value != null);
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        subscribeIf<TTarget = void>(args: [SubscriptionCallback<T, TTarget>, TTarget, "beforeChange" | "spectate" | "awake"], predicate?: (value: T) => boolean): Subscription;
        subscribeIf<TTarget = void>(args: [SubscriptionCallback<undefined, TTarget>, TTarget, "asleep"], predicate?: (value: T) => boolean): Subscription;
        subscribeIf<TTarget = void>(args: [SubscriptionCallback<T, TTarget>, TTarget | undefined, "change" | undefined], predicate?: (value: T) => boolean): Subscription;
        subscribeIf<TTarget = void>(args: [SubscriptionCallback<T, TTarget>, TTarget, string], predicate?: (value: T) => boolean): Subscription;
        subscribeIf<TTarget = void>(args: [SubscriptionCallback<T, TTarget>, TTarget], predicate?: (value: T) => boolean): Subscription;
        subscribeIf(args: [SubscriptionCallback<T, void>], predicate?: (value: T) => boolean): Subscription;

        subscribeNotNull<TTarget = void>(...args: [SubscriptionCallback<NonNullable<T>, TTarget>, TTarget, "beforeChange" | "spectate" | "awake"]): Subscription;
        subscribeNotNull<TTarget = void>(...args: [SubscriptionCallback<undefined, TTarget>, TTarget, "asleep"]): Subscription;
        subscribeNotNull<TTarget = void>(...args: [SubscriptionCallback<NonNullable<T>, TTarget>, TTarget | undefined, "change" | undefined]): Subscription;
        subscribeNotNull<TTarget = void>(...args: [SubscriptionCallback<NonNullable<T>, TTarget>, TTarget, string]): Subscription;
        subscribeNotNull<TTarget = void>(...args: [SubscriptionCallback<NonNullable<T>, TTarget>, TTarget]): Subscription;
        subscribeNotNull(...args: [SubscriptionCallback<NonNullable<T>, void>]): Subscription;
    }
}