import ko, { SubscribableOrValue } from 'knockout';
import { withEffects } from 'mixins/withEffects';

ko.virtualElements.allowedBindings.nodes = true

ko.bindingHandlers.nodes = {
    init: function (element, valueAccessor) {
        const effects = withEffects();
        const nodes = ko.flattenComputed(<SubscribableOrValue<Node[]>>valueAccessor(), []);

        effects.register(nodes => {
            ko.virtualElements.setDomNodeChildren(element, nodes);
        }, [nodes]);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => effects.dispose());

        return { controlsDescendantBindings: true };
    }
}