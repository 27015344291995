define('extensions/knockout/functions/toLock', [
    'knockout'
], function (ko) {
    ko.subscribable.fn.toLock = function () {
        let locked = ko.observable(false);

        let result = ko.pureComputed({
            read: () => this(),
            write: value => {
                locked(true);
                this(value);
                locked(false);
            }
        });

        result.locked = locked;

        return result;
    };
});