import ko, { Observable, PureComputed } from 'knockout';

interface StateTrackerOwner {
    __isAwake: Observable<boolean>
}

function hasStateTracker(target: PureComputed): target is PureComputed & StateTrackerOwner {
    return '__isAwake' in target;
}

ko.computed.fn.isAwake = function () {
    let observable = this;

    if (ko.isPureComputed(observable)) {
        if (!hasStateTracker(observable)) {
            const isAwake = ko.observable(observable.getSubscriptionsCount() > 0);

            observable.subscribe(() => isAwake(true), this, 'awake');
            observable.subscribe(() => isAwake(false), this, 'asleep');

            observable = _(observable).extend({ '__isAwake': isAwake }) as PureComputed & StateTrackerOwner;
        }

        return (<PureComputed & StateTrackerOwner>observable).__isAwake;
    }

    return ko.observable(true as boolean);
}

declare module 'knockout' {
    export interface ComputedFunctions<T> {
        /**
         * returns observable which indicates whatever original computed is awake(has subscriptions)
         */
        isAwake(): Subscribable<boolean>
    }
}