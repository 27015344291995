import ko, { SubscribableOrNullableValue } from 'knockout';
import { AnimationFrameContext, GenericAnimator } from "managers/genericAnimator";

function getAnimationDuration(animationName?: string) {
    switch (animationName) {
        case 'zoom': return 250;
        case 'fade': return 650;
        default: return 1000;
    }
}

export interface AppearBindingOptions {
    'animation'?: SubscribableOrNullableValue<string>
    'show-animation'?: SubscribableOrNullableValue<string>
    'hide-animation'?: SubscribableOrNullableValue<string>
    'initial-animation'?: SubscribableOrNullableValue<boolean>
    'visible'?: SubscribableOrNullableValue<boolean>
    'duration'?: SubscribableOrNullableValue<number>
    'on-start'?: SubscribableOrNullableValue<Action<[context: AnimationFrameContext<boolean>]>>
    'on-end'?: SubscribableOrNullableValue<Action<[context: AnimationFrameContext<boolean>]>>
}

/**
 * shows/hides node using animation
 */
ko.bindingHandlers.appear = {
    init: (element: HTMLElement, valueAccessor) => {
        var data = ko.pureComputed<AppearBindingOptions | undefined>(() => ko.unwrap(valueAccessor())),
            animationName = data.pluck('animation'),
            showAnimationName = data.pluck('show-animation').mapSingle(name => _.isString(name) ? name : animationName() ? `${animationName()}In` : 'fadeIn'),
            hideAnimationName = data.pluck('hide-animation').mapSingle(name => _.isString(name) ? name : animationName() ? `${animationName()}Out` : 'fadeOut'),
            shouldAnimateInitialFrame = data.pluck('initial-animation').mapSingle(value => _.isBoolean(value) ? value : false),
            onStartWrapper = data.pluck('on-start').mapSingle(func => _.isFunction(func) ? func as Action<[context: AnimationFrameContext<boolean>]> : _.noop),
            onEndWrapper = data.pluck('on-end').mapSingle(func => _.isFunction(func) ? func as Action<[context: AnimationFrameContext<boolean>]> : _.noop),
            isVisible = data.pluck('visible').mapSingle(value => _.isBoolean(value) ? value : true),
            cDuration = data.pluck('duration').mapSingle(value => _.isNumber(value) ? value : getAnimationDuration(animationName()));

        element.style.display = 'none';

        var animator = new GenericAnimator({
            state: isVisible,
            duration: cDuration,

            frame: function (context) {
                var isVisible = context.state,
                    progress = context.progress,
                    isInitial = context.isInitial,
                    onStart = onStartWrapper(),
                    onEnd = onEndWrapper();

                if (isInitial && !shouldAnimateInitialFrame()) {
                    if (isVisible) {
                        element.style.display = '';
                    } else {
                        element.style.display = 'none';
                    }

                    onStart(context);
                    onEnd(context);

                    return true;
                } else {
                    if (progress == 0) {
                        element.classList.add('wcc-animated');
                        element.style.animationDuration = cDuration() + 'ms';
                    }

                    if (isVisible) {
                        if (progress === 0) {
                            element.style.display = '';                      
                            element.classList.add(showAnimationName());
                        }

                        if (progress === 100)
                            element.classList.remove(showAnimationName());
                    } else {
                        if (progress == 0) {
                            element.classList.add(hideAnimationName());
                        }

                        if (progress === 100) {
                            element.style.display = 'none';
                            element.classList.remove(hideAnimationName());
                        }
                    }

                    if (progress === 0)
                        onStart(context);

                    if (progress == 100)
                        onEnd(context);
                }
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => animator.dispose());
    }
}