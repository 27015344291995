import { Func } from 'interfaces/func';
import ko from 'knockout';
import { MemoIterator, TypeOfList } from 'underscore';

ko.subscribable.fn.reduce = function <T, TResult>(iteratee: MemoIterator<TypeOfList<T>, TResult, T>, memoFactory?: Func<TResult>, context?: any) {
    return ko.pureComputed(() => memoFactory != undefined ? _(this()).reduce(iteratee, memoFactory(), context) : _(this()).reduce(iteratee));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed with value computed from inner array using provided reducer
         * @param iteratee reducer
         * @param memo inital value
         * @param context reducer this value
         */
        reduce<TResult>(iteratee: MemoIterator<TypeOfList<T>, TResult, T>, memoFactory: Func<TResult>, context?: any): Subscribable<TResult>

        /**
         * Returns computed with value computed from inner array using provided reducer
         * @param iteratee reducer
         */
        reduce<TResult>(iteratee: MemoIterator<TypeOfList<T>, TResult, T>): Subscribable<TResult>
    }
}