import { CollectionsHelpers } from 'helpers/collections';
import { Disposable } from 'interfaces/disposable';
import ko, { Subscribable } from 'knockout';
import { withEffects } from 'mixins/withEffects';

ko.subscribable.fn.attachTo = function <T>(sourceObservable: Subscribable<T>) {
    if (!ko.isWritableObservable(this))
        throw new Error('Observable is not writable');

    const observable = this;
    const effects = withEffects();

    const update = (value: T) => {
        //if it's an array we want to copy it to avoid reference issues
        if (_.isArray(value)) {
            if (!CollectionsHelpers.compareCollections(observable(), value))
                observable([...value]);
        } else {
            observable.setIfDifferent(value);
        }
    }

    effects.register(() => {
        const initialValue = observable(); //save observable value so we can restore it on dispose
        update(sourceObservable()); //write current source value

        return [
            sourceObservable.subscribe(value => update(value)), //write source value on change
            () => observable(initialValue) //set initial value as observable value on dispose
        ]
    });

    return effects;
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * links writable subscribable with any other subscribable. Every time source is changed it's value will be copied to current subscribable
         * @param source
         */
        attachTo(source: Subscribable<T>): Disposable
    }
}