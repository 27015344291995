import ko, { SimpleValue, Subscribable, SubscribableOrValue } from 'knockout';
import { CollectionKey } from 'underscore';
import { Func } from '../../../interfaces/func';

ko.subscribable.fn.groupBy = function <T>(this: Subscribable<T>, key: Func<SubscribableOrValue<string>, [item: SimpleValue<T>, idx: CollectionKey<T>]>) {
    return ko.pureComputed(() => _(this()).groupBy((item, idx) => ko.unwrap(key(<SimpleValue<T>>item, idx))));
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * Returns computed with inner array transformed into dictionary using key func
         * @param key key extractor
         */
        groupBy(key: Func<SubscribableOrValue<string>, [item: SimpleValue<T>, idx: CollectionKey<SimpleValue<T>>]>): Subscribable<StringMap<SimpleValue<T>[]>>
    }
}