import { Func } from 'interfaces/func';
import ko, { SimpleValue, Subscribable } from 'knockout';

ko.subscribable.fn.cache = function <T>(this: Subscribable<T>, key: Func<boolean, [SimpleValue<T>, SimpleValue<T>]> | keyof SimpleValue<T>, updateFunc?: Action<[SimpleValue<T>, SimpleValue<T>]>, cloneFunc?: Func<SimpleValue<T>, [SimpleValue<T>, number]>) {
    const map2Key = _.isFunction(key) ? key : <string>key;
    cloneFunc ??= _.identity;

    return this.map2(map2Key, cloneFunc, updateFunc);
}

declare module 'knockout' {
    export interface SubscribableFunctions<T> {
        /**
         * return computed with cached internal collection. 
         * allows to keep old items in dynamic collections. 
         * requires stable {key}
         * @param key
         */
        cache(key: Func<boolean, [SimpleValue<T>, SimpleValue<T>]> | keyof SimpleValue<T>): Subscribable<Array<SimpleValue<T>>>

        /**
         * return computed with cached internal collection. 
         * allows to keep old items in dynamic collections. 
         * updates cached items using {updateFunc} and new items. 
         * requires stable {key}
         * @param key
         * @param updateFunc
         */
        cache(key: Func<boolean, [SimpleValue<T>, SimpleValue<T>]> | keyof SimpleValue<T>, updateFunc: Action<[existingItem: SimpleValue<T>, newItem: SimpleValue<T>]>): Subscribable<Array<SimpleValue<T>>>

        /**
         * return computed with cached internal collection.
         * allows to keep old items in dynamic collections.
         * updates cached items using {updateFunc} and new items.
         * clones items before caching them using {cloneFunc}
         * requires stable {key}
         * @param key
         * @param updateFunc
         * @param cloneFunc
         */
        cache(key: Func<boolean, [SimpleValue<T>, SimpleValue<T>]> | keyof SimpleValue<T>, updateFunc: Action<[existingItem: SimpleValue<T>, newItem: SimpleValue<T>]>, cloneFunc: Func<SimpleValue<T>, [SimpleValue<T>, number]>): Subscribable<Array<SimpleValue<T>>>
    }
}