import _ from 'underscore/modules/index-default';

_.mixin({
    /**
     * @param {Array} arr
     * @param {Array} arr2
     */
    sameAs: function (arr, arr2) {
        if (arr != null && arr2 != null) {
            if (arr.length == arr2.length) {
                return _(arr)
                    .chain()
                    .zip(arr2)
                    .map(pair => pair[0] == pair[1])
                    .reduce((result, value) => result && value, true)
                    .value();
            }
        }

        return false;
    }
});