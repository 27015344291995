import _ from 'underscore/modules/index-default';

_.mixin({
    takeWhile: function (arr, predicate) {
        let count = 0;

        _(arr).all((item) => predicate(item) && ++count);

        return _(arr).take(count);
    }
});